import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { required, phoneNumber, email } from "helpers/form-validations";
import Input from "components/UI/Form/Input/Input";
import Form from "components/UI/Form/Form";

const formName = "AddEditVendor";

class AddEditVendorForm extends Component {
  render() {
    const {
      initialValues: { id },
      extraFields
    } = this.props;

    return (
      <div className="add-edit-vendor-modal">
        <div className="container">
          <div className="flex-col">
            <Form
              {...this.props}
              name={formName}
              id={formName}
              initialValues={{ ...extraFields, ...this.props.initialValues }}
              form={formName}
            >
              {({ changeFormValue, ...formProps }) => (
                <React.Fragment>
                  {id && (
                    <>
                      <div className="hide">
                        <Field
                          component={Input}
                          type="hidden"
                          name="brandId"
                          label="brandId"
                        />
                      </div>
                    </>
                  )}
                  <Field
                    component={Input}
                    validate={[required]}
                    type="text"
                    name="company"
                    label="Company"
                    required
                  />
                  <div className="fields-two">
                    <Field
                      component={Input}
                      validate={[required]}
                      type="text"
                      name="firstName"
                      label="First name"
                      required
                    />
                    <Field
                      component={Input}
                      type="text"
                      name="lastName"
                      label="Last name"
                    />
                  </div>
                  <Field
                    component={Input}
                    validate={[email]}
                    type="text"
                    name="email"
                    label="Email ID"
                  />
                  <Field
                    component={Input}
                    validate={[phoneNumber]}
                    type="number"
                    name="mobile"
                    label="Mobile number"
                  />
                </React.Fragment>
              )}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default AddEditVendorForm;
