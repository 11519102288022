import { PropTypes } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import FlashMessage from "./../../components/UI/FlashMessage/FlashMessage";
import closeIcon from "../../assets/images/navigation/ic-close-light.svg";
import successIcon from "../../assets/images/navigation/ic-success.svg";
import errorIcon from "../../assets/images/navigation/ic-error.svg";
import warningIcon from "../../assets/images/navigation/ic-warning.svg";
import infoIcon from "../../assets/images/navigation/ic-info-filled.svg";
import "./FlashMessageContainer.scss";
import actions from "../../store/actions";

const STATUS_IMAGE_LINK = {
  "success": successIcon,
  "info": infoIcon,
  "warning": warningIcon,
  "error": errorIcon
};

const STATUS_ALT_TEXT = {
  "success": "Success",
  "info": "Info",
  "warning": "Warning",
  "error": "Error"
}

class FlashMessageContainer extends Component {
  static propTypes = {
    flash: PropTypes.array.isRequired
  };

  // {
  //   actionType: "",
  //   type: "default" or "action"   action one is blue in bottom left.
  // }
  render() {
    return this.props.flash.length
      ? (() => {
          if (this.props.flash[0].type === "action") {
            // TODO: send `status` value with each message and use that here to show proper icon for that message. (e.g. Warning, Info, Error, Success Icons)
            var { message, actionType, status } = this.props.flash[0];

            if(!STATUS_IMAGE_LINK[status]) status = "success";   // After making corresponding changes everywhere in the code (sending status value) when we need to show toast, we'll change this status to 'info' by default.

            return (
              // toast message from bottom left on the screen
              <div className="flash-msg__container_action">
                <span className="status_icon">
                    <img
                      src={STATUS_IMAGE_LINK[status]}
                      alt={STATUS_ALT_TEXT[status]}
                    />
                </span>

                <span className="cancel_icon">
                  <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => this.props.hideAlert()}
                  />
                </span>

                <div className="flash-msg__action_body">{message && message }</div>
              </div>
            );
          }

          return (
            <div className="flash-msg__container">
              {this.props.flash.map((f, index) => (
                <FlashMessage message={f.message} key={index} />
              ))}
            </div>
          );
        })()
      : null;
  }
}

const mapStateToProps = state => ({
  flash: state.global.flash
});

const mapDispatchToProps = dispatch => ({
  hideAlert: details => dispatch(actions.hideFlashMessage())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlashMessageContainer);
