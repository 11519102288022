import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import Downshift from "downshift";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import icDropdown from "./../../../assets/images/navigation/ic-arrow-drop-down.svg";
import { AuthRoleIds } from "constants/Auth";

import "./AddUserModal.scss";
import Button from "components/UI/Button/Button";

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().nullable(),
  email: Yup.string().email().required("Required"),
  phone: Yup.string().required("Required"),
  role: Yup.string().required(),
  maxActiveQuery: Yup.number().when("role", {
    is: (o) =>
      Number(o) === AuthRoleIds.ACCOUNT_MANAGER || o === AuthRoleIds.SALES_HEAD,
    then: Yup.number().positive().min(5).max(60).required(),
    otherwise: Yup.number().nullable().optional()
  }),
});

const WithInput = ({
  label,
  value = "",
  type = "text",
  onChange,
  id,
  isError,
  required,
  ...restProps
}) => (
  <Field name={id}>
    {({ field }) => (
      <div className="inputField">
        <label className="label__class">
          {label} {required ? <sup>*</sup> : null}
        </label>
        <input data-invalid={isError} {...restProps} type={type} {...field} />
      </div>
    )}
  </Field>
);

const initialValues = {
  firstName: "",
  lastName: "",
  maxActiveQuery: 30,
};

const AddUserModal = ({ userData = {}, addLfUser, updateLfUser, canEditRole }) => {
  const editMode = !!userData.id;

  return (
    <div className="addLfUser">
      <Formik
        initialValues={editMode ? userData : initialValues}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          const request = {
            ...values,
            isLfUser: true,
            createdbyadmin: "1",
          };
          if (editMode) {
            request.id = userData.id;
            const data = {
              ...request,
              first_name: values.firstName,
              last_name: values.lastName,
              max_active_query: values.maxActiveQuery,
            }
            updateLfUser(data);
            return;
          }
          addLfUser(request);
        }}
      >
        {({ values, setFieldValue, handleSubmit, touched, errors, dirty }) => (
          <Form onSubmit={handleSubmit}>
            <PerfectScrollbar>
              <div className="formContent">
                <div className="fields-two">
                  <WithInput
                    label="First name"
                    id="firstName"
                    required
                    isError={touched.firstName && errors.firstName}
                    placeholder="E.g. John"
                  />
                  <WithInput
                    label="Last name"
                    id="lastName"
                    placeholder="E.g. Doe"
                    isError={touched.lastName && errors.lastName}
                  />
                </div>
                <WithInput
                  label="Email ID"
                  id="email"
                  required
                  isError={touched.email && errors.email}
                  placeholder="E.g. john.doe@company.com"
                />
                <WithInput
                  label="Mobile Number"
                  type="tel"
                  max="10"
                  required
                  id="phone"
                  isError={touched.phone && errors.phone}
                  placeholder="E.g. 9738839899"
                />
                {
                  canEditRole &&
                <div className="inputField">
                  <label className="label__class">
                    Role <sup>*</sup>
                  </label>
                  <Downshift
                    itemToString={(item) => item.label}
                    onChange={(selection) => {
                      if (
                        selection.value !== AuthRoleIds.ACCOUNT_MANAGER &&
                        selection.value !== AuthRoleIds.SALES_HEAD
                      ) {
                        setFieldValue("maxActiveQuery", null, false);
                      } else {
                        setFieldValue("maxActiveQuery", 30, false);
                      }
                      setFieldValue("role", selection.value);
                    }}
                    className="roleField"
                    initialSelectedItem={roleOptions.find(
                      (item) => item.value == values.role
                    )}
                  >
                    {({
                      getInputProps,
                      getToggleButtonProps,
                      getItemProps,
                      getLabelProps,
                      getMenuProps,
                      isOpen,
                      inputValue,
                      highlightedIndex,
                      selectedItem,
                    }) => {
                      return (
                        <div
                          className="downshift downshift-role-container"
                          {...getToggleButtonProps()}
                          type="none"
                        >
                          <span className="flex center justify-between">
                            <input
                              className="select-input-transparent"
                              readOnly
                              disabled
                              placeholder="Choose role"
                              {...getInputProps()}
                            />
                            <img src={icDropdown} alt="dropdown" />
                          </span>

                          {isOpen ? (
                            <div
                              className="select-pane"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <div>
                                <PerfectScrollbar>
                                  {roleOptions.map((item, index) => {
                                    return (
                                      <div
                                        {...getItemProps({
                                          className: `in-selection collection-collab__label_left_aigned flex ${
                                            selectedItem &&
                                            selectedItem.label === item.label
                                              ? "selected"
                                              : ""
                                          }`,
                                          index,
                                          item,
                                          key: item.label,
                                        })}
                                      >
                                        {item.label}
                                      </div>
                                    );
                                  })}
                                </PerfectScrollbar>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      );
                    }}
                  </Downshift>
                </div>
        }
                {(Number(values.role) === AuthRoleIds.ACCOUNT_MANAGER ||
                  Number(values.role) === AuthRoleIds.SALES_HEAD) && (
                  <Field name="maxActiveQuery">
                    {({ field }) => (
                      <div className="inputField">
                        <label className="label__class">
                          Active query limit
                        </label>
                        <div className="maxActiveQuery">
                          <Button
                            category="ghost"
                            type="button"
                            onClick={() => {
                              if (values[field.name] > 5) {
                                setFieldValue(
                                  field.name,
                                  values[field.name] - 1
                                );
                              }
                            }}
                          >
                            <span>-</span>
                          </Button>
                          <input type="number" data-invalid={errors[field.name]} {...field} />
                          <Button
                            category="ghost"
                            type="button"
                            onClick={() => {
                              if (values[field.name] < 60) {
                                setFieldValue(
                                  field.name,
                                  values[field.name] + 1
                                );
                              }
                            }}
                          >
                            <span>+</span>
                          </Button>
                        </div>
                      </div>
                    )}
                  </Field>
                )}
              </div>
            </PerfectScrollbar>

            {/* SUBMIT buttons */}

            <div className="modal__footer modal__footer-lfusers">
              <Button
                category="blue-bg"
                role="submit"
                disabled={!dirty || Object.keys(errors).length !== 0}
                onClick={handleSubmit}
              >
                SAVE
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const roleOptions = [
  {
    label: "Account Manager",
    value: AuthRoleIds.ACCOUNT_MANAGER,
  },
  {
    label: "Supplier Manager",
    value: AuthRoleIds.SUPPLIER_MANAGER,
  },
  {
    label: "City Head",
    value: AuthRoleIds.SALES_HEAD,
  },
  {
    label: "Logistics Manager",
    value: AuthRoleIds.LOGISTICS_MANAGER,
  },
  {
    label: "Supply Head",
    value: AuthRoleIds.SUPPLY_HEAD,
  },
];

export default AddUserModal;
