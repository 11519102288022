import React, { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import { required, passwordsMustMatch, newPasswordMustNotMatch } from "./../../../helpers/form-validations";
import Input from "../../UI/Form/Input/Input";
import Button from "./../../UI/Button/Button";

const formName = "ResetPasswordForm";

class ResetPasswordForm extends Component {
  render() {
    const {
      handleSubmit,
      pristine,
      hasSubmitButton = true
    } = this.props;

    return (
      <div className="flex-col">
        <Form name={formName} id={formName} onSubmit={handleSubmit}>
          <Field
            component={Input}
            validate={[required]}
            type="Password"
            name="oldPassword"
            label="Current password*"
            placeholder="Current password"
          />
          <Field
            component={Input}
            validate={[required, newPasswordMustNotMatch]}
            type="password"
            name="newPassword"
            label="New password*"
            placeholder="New password"
          />
          <Field
            component={Input}
            validate={[required, passwordsMustMatch]}
            type="password"
            name="confirmPassword"
            label="Confirm password*"
            placeholder="Confirm password"
          />
          {hasSubmitButton
            ? <Button disable={pristine} type="submit" category="large">
              SAVE
              </Button>
            : null}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps.initialValues ? { initialValues: { ...ownProps.initialValues } } : {},
  hasSubmitButton: ownProps.hasSubmitButton
})

export default reduxForm({ form: formName }, mapStateToProps)(
  ResetPasswordForm
);
