import { connect } from "react-redux";
import { getCurrentUserRole } from "../../../helpers/AuthHelper";

const PrivateContainer = ({
  allowRoles,
  denyRoles,
  render,
  children
}) => {
  const currentUserRole = getCurrentUserRole();

  // if allowRoles are defined, then allowRoles is tested
  if (Array.isArray(allowRoles) && (allowRoles.length <= 0 || allowRoles.indexOf(currentUserRole) < 0)) {
    return null;
  }

  // if denyRoles are defined, then denyRoles is tested
  if (Array.isArray(denyRoles) && (denyRoles.length <= 0 || denyRoles.indexOf(currentUserRole) >= 0)) {
    return null;
  }

  if (typeof (render) === 'function') {
    return render();
  }

  return children;
};

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  null
)(PrivateContainer);
