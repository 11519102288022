import React, { Component } from "react";
import './ErrorBoundaryContainer.scss';
import EmptyScreen from "../../components/UI/EmptyScreen";
import Button from "../../components/UI/Button/Button";
import Logo from "../../assets/images/locofast-blue.png";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true, error: error, errorInfo: info });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    console.log("---------------------------------------------------");
    console.log("Error:", error);
    console.log("***************************************************");
    console.log("ErrorInfo: ", info);
    console.log("---------------------------------------------------");
  }

  _handleNavigateToHome = () => {
    window.location.href = "/";
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div className="error-boundary-container">
        <EmptyScreen
          icon={Logo}
          iconCurved={false}
          heading="Oops!"
          description={<>
            We can’t seem to find the page<br />
            you’re looking for.
          </>}
          cta={<Button
            category="blue uppercase filter"
            onClick={() => this._handleNavigateToHome()}
          >GO TO HOME</Button>}
        />
      </div>
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
