import React from "react";
import { Table } from 'antd'

// import Pagination from "../../../components/Product/Pagination";

// styles
import 'antd/dist/antd.css'
import './table.scss'
import moment from "moment";

const columns = [
  {
    title: 'SM',
    dataIndex: 'name',
    key: 'name',
    width: 120,
    fixed: 'left',
    align: 'center',
    className: 'fixed-column'
  },
  {
    title: 'Requests Assigned',
    dataIndex: 'requestsAssigned',
    key: 'requestsAssigned',
    align: 'center',
  },
  {
    title: 'Unanswered',
    dataIndex: 'unanswered',
    key: 'unanswered',
    align: 'center'
  },
  {
    title: 'Quotes Submitted',
    dataIndex: 'quoteSubmitted',
    key: 'quoteSubmitted',
    align: 'center'
  },
  {
    title: 'Avg Quotes Per Query',
    dataIndex: 'averageQuotesPerQuery',
    key: 'averageQuotesPerQuery',
    align: 'center'
  },
  {
    title: 'Avg First Quotes Time',
    dataIndex: 'firstQuoteTime',
    key: 'firstQuoteTime',
    align: 'center',
    render: (val) => {
      return (<div className='relative'>
        {val?.avg || 0}
        <p className='avg-quotes-range'>[{val?.min || 0}, {val?.max || 0}]</p>
      </div>
      )
    }
  },
  {
    title: 'Supplier Activity',
    dataIndex: 'supplierActivity',
    key: 'supplierActivity',
    align: 'center'
  },
  {
    title: 'New Supplier Added',
    dataIndex: 'newSupplierAdded',
    key: 'newSupplierAdded',
    align: 'center'
  },
  {
    title: 'Orders',
    children: [
      {
        title: 'Quantity booked',
        dataIndex: 'quantityBooked',
        key: 'quantityBooked',
        align: 'right',
        render: (val) => {
          if (val?.length === 0 || Object.keys(val)?.length === 0) {
            return '-'
          }
          return (
            val && Array.isArray(val) && val?.map(i => {
              return (
                <div className='relative quantity-booked'>
                  <p className='quantity-value'>{i?.quantity} {i?.unit}</p>
                  <span span className='quantity-circled-value'> {i?.count} </span>
                </div>
              )
            })
          )
        }
      },
      {
        title: 'Sales value Excl. GST',
        dataIndex: 'salesValue',
        key: 'salesValue',
        align: 'center',
      },
      {
        title: 'Profit %',
        dataIndex: 'profitPercentage',
        key: 'profitPercentage',
        width: 180,
        align: 'center',
        render: (val) => {
          return (<div className='relative margin-value'>
            {val?.avg || 0}
            <p className='avg-quotes-range'>[{val?.min || 0}, {val?.max || 0}]</p>
          </div>
          )
        }
      },
      {
        title: 'New Suppliers',
        dataIndex: 'firstOrderSuppliersCount',
        key: 'firstOrderSuppliersCount',
        align: 'center'
      },
      {
        title: 'Processing',
        dataIndex: 'processingOrdersCount',
        key: 'processingOrdersCount',
        align: 'center'
      },
      {
        title: 'Weaving',
        dataIndex: 'weavingOrdersCount',
        key: 'weavingOrdersCount',
        align: 'center',
      },
    ]
  },
  {
    title: 'Planned TNA',
    children: [
      {
        title: 'Filled in time',
        dataIndex: 'tnaCount',
        key: 'tnaCount',
        align: 'center',
        render: (val) => {
          return <p>{val?.filledInTime}</p>
        }
      },
      {
        title: 'Not filled in time',
        dataIndex: 'tnaCount',
        key: 'tnaCount-notFilled',
        align: 'center',
        className: 'column-right-border',
        render: (val) => {
          return <p>{val?.notFilledInTime}</p>
        }
      },
    ]
  },
]

const SMTable = props => {

  const { data, currentIndex, paginate, paginationHandler, isPastWeek, minDate, maxDate, daysResults } = props

  const getDaysResults = () => {
    let diff = moment(maxDate).diff(moment(minDate), 'days')
    switch (diff) {
      case 7:
        return 'past 7 days'
      case 14:
        return 'past 14 days'
      case 0:
        return 'Yesterday'
      case 30:
      case 31:
        return 'past 30 days'
      default:
        return `${minDate} - ${maxDate}`
    }
  }

  return (
    <>
      <div className="table-container">
        <Table
          columns={columns}
          dataSource={data}
          bordered
          size="middle"
          scroll={{ x: 'calc(1500px + 50%)' }}
          // scroll={{ x: 2560 }}
          rowClassName='row-table'
          pagination={false}
        />
      </div>
      <div
        className='bottom-bar'
      >
        <div className='content'>
          Showing results from {
            getDaysResults()
          }
          <span>Indian customers only</span>
        </div>
        <div>
          {/* <Pagination
            label="orders"
            onclick={paginationHandler}
            page={
              paginate && {
                ...paginate,
                totalCount: paginate?.count?.supplierManagers
              }
            }
            currentIndex={currentIndex}
            showTotalRecords={false}
            className="pagination-wrapper"
          /> */}
        </div>
      </div>
    </>
  );
}

export default SMTable;
