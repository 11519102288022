import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import moment from "moment";

import AMTable from "./Table/AMTable";
import PerformanceHeader from "./Header/index";
import SMTable from "./Table/SMTable";
import actions from "store/actions";
import { AM, DATE_FORMAT_PATTERN, QUICK_MENU_OPTIONS, RESULTS_PER_PAGE_LIMIT } from "constants/PerformanceReport";
import { ACCOUNT_MANAGER, AuthRoleIds, SUPPLIER_MANAGER } from "constants/Auth";

import "./style.scss";

const Performance = props => {
  const { getSMReport, smReports, getAMReport, amReports, selectedTabFilter } = props

  const [currentSMPage, setCurrentSMPage] = useState(0)
  const [currentAMPage, setCurrentAMPage] = useState(0)


  const currentDate = moment()
  const yesterday = moment().subtract(1, 'day')
  const aWeekBackDate = moment().subtract(1, 'week')
  const twoWeeksBackDate = moment().subtract(2, 'week')
  const aMonthBackDate = moment().subtract(1, 'month')

  const [dates, setDates] = useState({
    minDate: aWeekBackDate,
    maxDate: currentDate
  })


  const [filterDates, setFilterDates] = useState({
    minDate: aWeekBackDate,
    maxDate: currentDate
  })



  const { maxDate, minDate } = dates


  const [quickMenuOption, setQuickMenuOption] = useState(QUICK_MENU_OPTIONS[2])

  const getFormattedDate = (date) => {
    return moment(date).format(DATE_FORMAT_PATTERN)
  }

  const getDateAndMonth = (date) => {
    if (date) {
      return moment(date).format('DD MMM')
    } return null
  }

  const apply = () => {
    setFilterDates(dates)
  }

  const clear = () => {
    setFilterDates({
      minDate: aWeekBackDate,
      maxDate: currentDate
    })
    setQuickMenuOption('yesterday')
  }

  const setDate = (option) => {
    switch (option) {
      case 'yesterday':
        setDates({ minDate: yesterday, maxDate: yesterday })
        break
      case 'past 14 days':
        setDates({ minDate: twoWeeksBackDate, maxDate: currentDate })
        break
      case 'past 7 days ':
        setDates({ minDate: aWeekBackDate, maxDate: currentDate })
        break
      case 'past 30 days':
        setDates({ minDate: aMonthBackDate, maxDate: currentDate })
        break
      default:
        setDates({ minDate: aWeekBackDate, maxDate: currentDate })
        break
    }
  }

  useEffect(() => {
    setDate(quickMenuOption)
  }, [quickMenuOption])

  useEffect(() => {
    let params = {
      limit: RESULTS_PER_PAGE_LIMIT,
      role: AuthRoleIds[SUPPLIER_MANAGER],
      page: currentSMPage + 1,
      minDate: getFormattedDate(filterDates?.minDate),
      maxDate: getFormattedDate(filterDates?.maxDate)
    }
    if (filterDates?.minDate && filterDates?.maxDate) {
      getSMReport(params)
    }
  }, [currentSMPage, filterDates.maxDate, filterDates.minDate, getSMReport])

  useEffect(() => {
    let params = {
      limit: RESULTS_PER_PAGE_LIMIT,
      role: AuthRoleIds[ACCOUNT_MANAGER],
      page: currentAMPage + 1,
      minDate: getFormattedDate(filterDates?.minDate),
      maxDate: getFormattedDate(filterDates?.maxDate)
    }
    if (filterDates?.minDate && filterDates?.maxDate) {
      getAMReport(params)
    }
  }, [currentAMPage, filterDates.maxDate, filterDates.minDate, getAMReport])

  const paginationSMHandler = ({ isnext }) => {
    if (isnext) {
      const reportsLength = smReports?.records?.length - 1;

      if (reportsLength > currentSMPage) {
        setCurrentSMPage(currentSMPage + 1)
      } else {
        setCurrentSMPage(currentSMPage + 1)
      }
      return;
    }

    if (!isnext) {
      setCurrentSMPage(currentSMPage - 1);
      return;
    }
  };
  const paginationAMHandler = ({ isnext }) => {
    if (isnext) {
      const reportsLength = amReports?.records?.length - 1;

      if (reportsLength > currentAMPage) {
        setCurrentAMPage(currentAMPage + 1)
      } else {
        setCurrentAMPage(currentAMPage + 1)
      }
      return;
    }

    if (!isnext) {
      setCurrentAMPage(currentAMPage - 1);
      return;
    }
  };

  return <>
    <PerformanceHeader
      selectedTabFilter={selectedTabFilter}
      pagination={smReports?.paginate}
      setQuickMenuOption={setQuickMenuOption}
      quickMenuOption={quickMenuOption}
      dates={dates}
      setDates={setDates}
      apply={apply}
      clear={clear}
      filterDates={filterDates}
    />
    {
      selectedTabFilter === AM ?
        <AMTable
          data={amReports?.records}
          paginate={amReports?.paginate}
          currentIndex={currentAMPage}
          paginationHandler={paginationAMHandler}
          minDate={getDateAndMonth(filterDates?.minDate)}
          maxDate={getDateAndMonth(filterDates?.maxDate)}
        /> :
        <SMTable
          data={smReports?.records}
          paginate={smReports?.paginate}
          currentIndex={currentSMPage}
          paginationHandler={paginationSMHandler}
          minDate={getDateAndMonth(filterDates?.minDate)}
          maxDate={getDateAndMonth(filterDates?.maxDate)}
        />
    }
  </>;
}

const mapStateToProps = state => ({
  smReports: state.performance.smReports,
  amReports: state.performance.amReports,
  loading: state.performance.isSMLoading
});

const mapDispatchToProps = dispatch => ({
  getSMReport: (params) => dispatch(actions.getSMReport(params)),
  getAMReport: (params) => dispatch(actions.getAMReport(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Performance);
