import React from 'react'
import './styles.scss';

const Input = ({ field,
  form: { touched, errors },
  label,
  name,
  required,
  className,
  alignment = "vertical",
  ...props }) => {

  let fullClassName = className || '';

  if (props.type === 'hidden') {
    fullClassName += ' formik_input--hidden';
  }

  return (
    <div className={`formik-field formik_input ${alignment} ${fullClassName}`}>
      {label && <label htmlFor={name} className="label">
        {label}
        {
          required && <sup>*</sup>
        }
      </label>}
      <input {...field} {...props} />
      {touched[field.name] &&
        errors[field.name] && <div className="error">{errors[field.name]}</div>}
    </div>
  );
};

export default Input;
