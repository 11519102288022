import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Popover, Button } from 'antd';

import Tabs from '../../UI/UrlTabs/index';
// import { DatePicker } from 'components/Reports/UI/Filters/DateRangePicker';
import { DATE_FORMAT_PATTERN, MAP_TAB_URL_COUNT, QUICK_MENU_OPTIONS, SM, TABS } from 'constants/PerformanceReport';

import './style.scss';
const Header = props => {

  const {
    pagination,
    selectedTabFilter = SM,
    setQuickMenuOption,
    quickMenuOption,
    apply,
    filterDates,
    clear,
  } = props;

  const mapTabs = TABS;

  const getDateAndMonth = (date) => {
    if (date) {
      return moment(date).format('DD MMM')
    } return null
  }

  const content = (
    <div className='filter-dropdown'>
      <p className='filter-heading'>Choose a date window</p>

      <div className='quick-menu-container'>
        <p className='quick-menu-label'>Quick Menu</p>
        <div className='quick-menu-btns'>
          {
            QUICK_MENU_OPTIONS.map((option, index) => {
              return (
                <Button
                  key={index}
                  className={`quick-menu-btn ${option === quickMenuOption && 'quick-menu-btn-active'}`}
                  onClick={() => setQuickMenuOption(option)}
                >
                  {option}
                </Button>
              )
            })
          }
        </div>
      </div>
      <div className='date-range-container'>
        <p className='quick-menu-label'>CHOOSE A RANGE</p>
        <div className='date-range'>
          {/* <DatePicker
            label=""
            updateFilterDates={dates => {
              props.setDates({ ...dates });
            }}
            bordered={false}
            dropdownClassName='calendar'
            selectedDates={props.dates}
          /> */}
        </div>
      </div>

      <div className='filter-button-container'>
        <p className='clear-btn' onClick={clear}>Clear All</p>
        <div className='apply-btn-container'>
          <Button className='apply-btn' onClick={apply}>Apply</Button>
        </div>
      </div>

    </div>
  );

  return (
    <div className='quotes-header sticky-header'>
      <Tabs
        hasBadges={true}
        initialTabUrl={selectedTabFilter}
        noBrackets
        tabs={Object.entries(mapTabs).map(([key, tab]) => ({
          ...tab,
          badge: (pagination && pagination.count[`${MAP_TAB_URL_COUNT[tab.tabUrl]}`]) || 0,
        })
        )
        }
      />
      <div className='popup-container'>
        <Popover
          placement="bottom"
          content={content}
          trigger="click"
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          <Button className='calendar-button'>
            <img src={require('../../../assets/images/calendar.svg')} alt='' />
            {getDateAndMonth(filterDates?.minDate)} - {getDateAndMonth(filterDates?.maxDate)}</Button>
        </Popover>
      </div>

    </div>
  );
}

export default Header
