const colorsList = [
  "#E57373",
  "#F06292",
  "#BA68C8",
  "#9575CD",
  "#7986CB",
  "#64B5F6",
  "#4fC3F7",
  "#4DD0E1",
  "#4DB6AC",
  "#81C784",
  "#AED581",
  "#DCE775",
  "#FFF176",
  "#5A4FFF",
  "#FFA276",
  "#FF8A65",
  "#A1887F",
  "#E0E0E0",
  "#90A4AE"
]

export const colors = id => colorsList[id%colorsList.length];

