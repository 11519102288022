import React from 'react';
import { Helmet } from 'react-helmet'
import qs from 'query-string';

import Performance from '../../components/Performance/index';
import { PAGE_TITLES } from "../../constants/Titles";

const DEFAULT_SELECTED_TAB_FILTER = 'sm';

const getSelectedTabFilter = (location) => {
  return (location
    && location.search
    && qs.parse(window.appHistory.location.search.slice(1))['tab'])
    || DEFAULT_SELECTED_TAB_FILTER;
}
class PerformanceContainer extends React.Component {
  state = {
    selectedTabFilter: DEFAULT_SELECTED_TAB_FILTER,
  };

  constructor(props) {
    super(props);
    const {
      location
    } = this.props;

    this.state.selectedTabFilter = getSelectedTabFilter(location);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location) {
      const selectedTabFilter = getSelectedTabFilter(nextProps.location);

      if (selectedTabFilter !== prevState.selectedTabFilter) {
        return { selectedTabFilter };
      }
    }
    return null;
  }

  render() {
    const {
      openModal,
      userDetails,
    } = this.props;
    const { selectedTabFilter } = this.state;
    return (
      <div className='quotes-container scrollable' style={{ overflow: 'scroll' }}>
        <Helmet
          bodyAttributes={{
            class: 'body_container'
          }}
          title={PAGE_TITLES.performance()}
        />
        <Performance
          openModal={openModal}
          userDetails={userDetails}
          selectedTabFilter={selectedTabFilter}
        />
      </div>
    );
  }
}

export default PerformanceContainer
