import React from 'react';
import OrgRowDetailsTab from "components/AccountSettings/OrgSettings/OrgRowDetailsTab";
import OrgUsersTabPanel from 'components/AccountSettings/OrgSettings/OrgUsersTabPanel';
import Button from 'components/UI/Button/Button';
import { IconUnlink, IconEditPencil } from 'components/UI/Icons';
import { ORG_TYPES } from 'constants/Global';

const OrgRowDetails = ({
  org,
  orgUsers = [],
  accountManagers = [],
  onAddUser,
  onEditUser,
  onAssignAM,
  onUnassignAM,
}) => {
  return <OrgRowDetailsTab>
    {org.type === ORG_TYPES.brand && OrgUsersTabPanel({
      header: `Users (${orgUsers.length})`,
      users: orgUsers,
      gridTemplateColumns: "1fr 1fr 1fr 32px",
      onAdd: () => onAddUser(org),
      ctas: ((user) => <>
        <Button
          category="img shrink"
          className="cta-on-hover cta-edit"
          onClick={() => onEditUser(org, user)}
        >
          <IconEditPencil />
        </Button>
      </>),
    })}
    {org.type === ORG_TYPES.fabricCustomer && OrgUsersTabPanel({
      header: `Account Managers (${accountManagers.length})`,
      users: accountManagers,
      gridTemplateColumns: "1fr 1fr 1fr 32px",
      onAdd: () => onAssignAM(org),
      ctas: ((user) => <>
        <Button
          category="img shrink"
          className="cta-on-hover cta-unassign"
          onClick={() => onUnassignAM(org, user)}
        >
          <IconUnlink />
        </Button>
      </>),
    })}
  </OrgRowDetailsTab>;
};

export default OrgRowDetails;
