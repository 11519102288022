import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../../UI/Button/Button";
import "./SuccessfullSignup.scss";

export default class SuccessfullSignup extends Component {
  render() {
    return (
      <div className="signup-success">
        <div className="signup-success__title f-w-bold">Talk to you soon!</div>
        <div className="signup-success__content">
          Thank you for your interest in Locofast. On of our team member will
          reach out to you shortly - usually within 1 business day.
        </div>
        <Link to="/brand/settings/profile">
          <Button className="f-w-bold" category="rounded faded">
            Sign in &nbsp;&nbsp;
            <i class="fa fa-arrow-right" />
          </Button>
        </Link>
      </div>
    );
  }
}
