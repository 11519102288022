export const required = value =>
  value || typeof value === "number" ? undefined : "This field cannot be empty";

export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? "No special characters allowed"
    : undefined;

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const number = value =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;

export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;

  export const maxValue = max => value =>
    value && value > max ? `Must be at most ${max}` : undefined;

export const decimalUpto2 = value =>
  value && !(/^\d*(\.\d{1,2})?$/.test(value))
    ? "Should be more than zero and up to 2 decimals."
    : undefined;

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? "Invalid phone number, must be 10 digits"
    : undefined;

export const customVendorValidate = value =>
  value && !value[0].price ? "This field cannot be empty" : undefined

export const sanitised = value =>
  value && typeof value === 'string' ? value.indexOf("script") > -1 ? "Invalid input" : undefined : undefined

export const passwordsMustMatch = (value, allValues) => {
  if ('newPassword' in allValues) {
    return allValues.newPassword !== value ? "Passwords do not match" : undefined
  }
  return allValues.password !== value ? "Passwords do not match" : undefined
};

export const newPasswordMustNotMatch = (value, allValues) => {
  return allValues.oldPassword === value ? "Current and new password cannot not be same" : undefined
};

export const alphaNumericAndSpecial = value =>
  value && !/^[a-zA-Z0-9-,. !'"?()_]+$/.test(value)
    ? "No special characters allowed except for -,. !'\"?()_"
    : undefined;

export const validFileTypes = fileTypes => value =>
  value && value instanceof FileList && Array.from(value).some(f => fileTypes.indexOf(f.type) < 0)
    ? "Invalid file type"
    : undefined;
