import React, { useCallback } from "react";
import OrgTable from "../../OrgSettings/CourierPartners/OrgTable";
import OrgRowDetails from "../OrgRowDetails";
import "./style.scss";

const Table = ({
  data,
  onOrgEdit = (org) => null,
  expandable = true
}) => {
  const rowRenderer = useCallback((org) => {
    return (
      <>
        <div>{org.name}</div>
        <div>
          <a
            href={`http://${org.baseUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {org.baseUrl}
          </a>
        </div>      
        <div>
          {org.isActive === 1 ? "Active" : "Inactive"}
        </div>
        <div>
          {org.trackingIdConstraint}
        </div>
      </>
    );
  }, []);

  const rowDetailsRenderer = useCallback(
    (org) => {
      return (
          <OrgRowDetails
            org={org}
          />
      );
    },
    []
  );

  return (
    <OrgTable
      className="partner-org-table"
      headers={["PARTNER", "BASE URL", "STATUS", "CONSTRAINTS", null]}
      data={data}
      gridTemplateColumns="1fr 2fr 2fr 1fr"
      rowRenderer={rowRenderer}
      rowDetailsRenderer={rowDetailsRenderer}
      onOrgEdit={onOrgEdit}
      expandable={expandable}
    />
  );
};

export default Table;
