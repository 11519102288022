import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import qs from "query-string";
import "./style.scss";

class Tabs extends Component {
  state = {
    selectedUrl: "",
    TabPanels: [],
    propsTabs: null
  };

  static getDerivedStateFromProps(nextProps, oldState) {
    const nextState = {};

    if (nextProps.tabs !== oldState.propsTabs) {
      const queryString = qs.parse(nextProps.location.search);
      const newTabPanels = nextProps.tabs.map(x => ({
        panel: x ? x.panel : null,
        url: x ? x["tabUrl"] : "#"
      }));

      // Fallback to props.initialTabUrl
      let newTabUrl = nextProps.initialTabUrl;
      const newTabIndex = newTabPanels.findIndex(
        val => val["url"] === queryString["tab"]
      );

      if (newTabIndex !== -1) {
        // Tab found
        newTabUrl = queryString["tab"];
      } else {
        nextProps.history.push({
          search: qs.stringify({ ...queryString, tab: newTabUrl }),
          poItemDetails: nextProps.location.state
            ? nextProps.location.state.data
            : nextProps.location.poItemDetails,
          searchText: nextProps.location.pathFilters
            ? nextProps.location.pathFilters.searchText
            : null
        });
        return;
      }

      nextState.TabPanels = newTabPanels;
      nextState.selectedUrl = newTabUrl;
      nextState.propsTabs = nextProps.tabs;
    }

    return Object.keys(nextState).length > 0 ? nextState : null;
  }

  render() {
    const { props } = this;

    return (
      <div className="tabs flex-col">
        <div className="tabs__header flex-col">
          <div className="tabs__custom-header">{props.header}</div>
        </div>
        <div className="tabs__navbar flex">
          {props.tabs
            .filter(t => (t["position"] ? t["position"] !== "right" : true))
            .map((t, index) => (
              <div
                key={index}
                className={`tabs__tab flex${this.state.selectedUrl === t["tabUrl"]
                    ? " tabs__tab--active"
                    : ""
                  }`}
                onClick={() => {
                  console.log("tabClick", {
                    // selected: index,
                    selectedUrl: t["tabUrl"]
                  });
                  this.setState({
                    // selected: index,
                    selectedUrl: t["tabUrl"]
                  });
                  // if (t["tabUrl"]) this.props.history.push(`?${t["tabUrl"]}`);
                  if (t["tabUrl"]) {
                    this.props.history.push({
                      search: qs.stringify({
                        ...qs.parse(this.props.location.search),
                        tab: t["tabUrl"]
                      })
                    });
                  }
                }}
              >
                <span>
                  {t.name}
                  {
                    props.noBrackets ?
                      props.hasBadges && `  ${t.badge}` :
                      props.hasBadges && ` (${t.badge})`
                  }
                </span>
              </div>
            ))}
          <div style={{ marginLeft: "auto" }}>
            {props.tabs
              .filter(t => (t["position"] ? t["position"] === "right" : false))
              .map((t, index) => (
                <div
                  key={index}
                  className={`tabs__tab flex${this.state.selectedUrl === t["tabUrl"]
                      ? " tabs__tab--active"
                      : ""
                    }`}
                  onClick={() => {
                    this.setState({
                      // selected: index,
                      selectedUrl: t["tabUrl"]
                    });
                    // if (t["tabUrl"]) this.props.history.push(`?${t["tabUrl"]}`);

                    if (t["tabUrl"]) {
                      this.props.history.push({
                        search: qs.stringify({
                          ...qs.parse(this.props.location.search),
                          tab: t["tabUrl"]
                        })
                      });
                    }
                  }}
                >
                  <span>
                    {t.name}
                    {props.hasBadges && ` (${t.badge})`}
                  </span>
                </div>
              ))}
          </div>
        </div>
        <div className="tabs__panel flex-col">
          {this.state.selectedUrl
            ? // _.get(
            //     this.state.TabPanels,
            //     this.state.TabPanels.findIndex(val => val["url"] === this.state.selectedUrl),
            //     this.state.TabPanels.findIndex(val => val["url"] === this.props.initialTabUrl)
            //   ).panel
            this.state.TabPanels[
              this.state.TabPanels.findIndex(
                val => val["url"] === this.state.selectedUrl
              )
            ].panel
            : null}
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired
};

export default withRouter(Tabs);
