import React, { useEffect, useMemo, useState } from 'react';
import * as qs from 'query-string'
import { connect } from 'react-redux';
import Table from './Table';
import EmptyScreen from './EmptyScreen';
import {
  getMills,
} from 'store/actions/UserActions';
import { PageHeader, Pagination, Input } from 'antd';
import './style.scss';
import history from 'utilites/history';

const Mills = ({
  mills,
  getMills,
  path,
  ...restProps
}) => {
  const queryParams = qs.parse(restProps.location.search)
  const { Search } = Input
  const [totalMills, setTotalMills] = useState(0)
  const [currentPage, setCurrentPage] = useState(+queryParams.page ||1)
  const hasMills = useMemo(() => mills && mills.data.length > 0, [mills]);
  const [searchInput, setSearchInput] = useState(queryParams.search ||'')
  const [isSearchData, setIsSearchedData] = useState(false)

  useEffect(() => {
    getMills({limit: 10, offset: ((+queryParams.page || currentPage) - 1) * 10, search: queryParams.search ?? ''})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTotalMills(mills?.data[0]?.totalSuppliersCount?.count)
  }, [mills.data])

  const handleChange = (pageNumber) => {
    getMills({limit: 10, offset: (pageNumber - 1) * 10, search: searchInput})
    setCurrentPage(pageNumber)
  }

  function onSearchInput(value) {
    getMills({search: (value ?? searchInput)})
    history.push(`/factory/settings/mills/?search=${value ?? searchInput}`)
    setCurrentPage(1)
  }

  return (<div className="settings-mills">
    <PageHeader
        className="site-page-header"
        title="Mills"
        extra={[
          <Search
            placeholder="Input search text"
            allowClear
            enterButton
            className="search-input"
            value={searchInput}
            onPressEnter={() => onSearchInput()}
            onSearch={onSearchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        ]}
      />
    {
      hasMills
        ? <>
            <Table
              data={mills.data}
              path={path}
              queryParams={{...queryParams, page: currentPage}}
              onChange={handleChange}
            />
            <div className='millsPagination'>
            {!isSearchData && <Pagination defaultCurrent={currentPage} total={totalMills} onChange={handleChange} current={currentPage} />}
            </div>
          </>
        : <EmptyScreen
        />
    }
  </div>);
};

const mapStateToProps = (state, props) => ({
  mills: props.users || state.user.mills,
});

const mapDispatchToProps = (dispatch) => ({
  getMills: (data) => dispatch(getMills(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Mills);
