import { PropTypes } from "prop-types";
import React from "react";
import "./FlashMessage.scss";

const FlashMessage = ({ message="Something went wrong!", type="default" }) => {
  return <div className="flash-msg">{message}</div>;
};

FlashMessage.propTypes = {
  message: PropTypes.string.isRequired
};

export default FlashMessage;
