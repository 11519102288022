import React, { Component } from "react";
import { Form, reduxForm } from "redux-form";
import CloseImg from "../../../assets/images/navigation/ic-close.svg";
import _ from "lodash";
import "./InviteUsersForm.scss";

const formName = "InviteUsers";

class InviteUsersForm extends Component {
  onKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault(); //<===== This stops the form from being submitted
    }
  };
  state = {
    selectedUsers: [],
    error: false
  };

  deleteUser = userIndex => () => {
    let newUsers = this.state.selectedUsers;
    newUsers.splice(userIndex, 1);
    this.setState({
      selectedUsers: newUsers
    });
  };

  componentDidUpdate() {
    this.props.change("emails", this.state.selectedUsers);
  }
  render() {
    const {
      handleSubmit,
      // pristine,
      // reset,
      // submitting,
      change,
      hiddenFields
    } = this.props;

    // Add hidden fields
    if (hiddenFields) hiddenFields.forEach(f => change(f.name, f.value));

    return (
      <div className="flex-col" style={{ padding: "1em 2em", flexGrow: 1 }}>
        <div className="inv-users__content">
          Invited users will receive invitations to join your company account.
        </div>
        <Form
          name={formName}
          id={formName}
          onSubmit={handleSubmit(values => {
            if (_.get(values, "emails", []).length > 0)
              this.props.onSubmit(values);
          })}
          //   onSubmit={handleSubmit}
          onKeyPress={this.onKeyPress}
        >
          <div className="input-grp">
            <label htmlFor="emailAddrs" className="f-sz-sm f-color-faded">
              Enter Email
            </label>
            <input
              id="emailAddrs"
              type="email"
              // onChange={() => this.setState({ error: false })}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  if (
                    /\S+@\S+\.\S+/.test(e.target.value) &&
                    !this.state.selectedUsers.includes(e.target.value)
                  ) {
                    this.setState({
                      errorMsg: null,
                      selectedUsers: [
                        ...this.state.selectedUsers,
                        e.target.value
                      ]
                    });
                    e.target.value = "";
                  } else if (
                    this.state.selectedUsers.includes(e.target.value)
                  ) {
                    this.setState({
                      errorMsg: "Email already exists in the list."
                    });
                  } else {
                    this.setState({
                      errorMsg: "Not a valid email Address."
                    });
                  }
                }
              }}
            />
            {this.state.errorMsg !== null && (
              <span className="error">&nbsp;&nbsp;{this.state.errorMsg}</span>
            )}
          </div>
          {this.state.selectedUsers.length === 0 ? null : (
            <div className="invite-users__multi-select">
              {this.state.selectedUsers.map((user, index) => (
                <div className="invite-users__item flex" key={user}>
                  <div className="f-w-semi-bold">{user}</div>
                  <div
                    className="invite-users__del"
                    onClick={this.deleteUser(index)}
                  >
                    <img height="15" src={CloseImg} alt="close modal" />
                  </div>
                </div>
              ))}
            </div>
          )}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) =>
  ownProps.initialValues
    ? {
        initialValues: { ...ownProps.initialValues }
      }
    : {};

export default reduxForm({ form: formName }, mapStateToProps)(InviteUsersForm);
