import axios from "axios";
import { createAction } from "redux-actions";
import { get_service_endpoint } from "ServiceEndpoints";
import { flash } from "../actions/CommonActions";
const GET_MATERIAL_DATA = createAction("GET_MATERIAL_DATA");
const GET_TOP_SELL_TABLE_DATA = createAction("GET_TOP_SELL_TABLE_DATA");
const GET_DISABLE_TABLE_DATA = createAction("GET_DISABLE_TABLE_DATA");
const GET_SUPPLIER_DATA = createAction("GET_SUPPLIER_DATA");

const fabricQualities = get_service_endpoint("fabricQualities");
const fabric = get_service_endpoint("fabric");

export const getTopSellingTableData = (params) => async (dispatch) => {
  try {
    const res = await axios.get(`${fabricQualities}/top-selling`, { params });
    if (params.isDisable) {
      dispatch(GET_DISABLE_TABLE_DATA(res.data.data));
    } else {
      dispatch(GET_TOP_SELL_TABLE_DATA(res.data.data));
    }
  } catch (err) {
    console.log(err);
    dispatch(
      flash({
        message: err.msg,
      })
    );
  }
};
export const getSupplier = (params) => async (dispatch) => {
  try {
    const res = await axios.get(`${fabric}/suppliers/all-suppliers`, { params });
    dispatch(GET_SUPPLIER_DATA(res.data.data));   
    return res.data.data;
  } catch (err) {
    console.log(err);
    dispatch(
      flash({
        message: err.msg,
      })
    );
  }
};
export const getMaterialData = () => async (dispatch) => {
  try {
    const res = await axios.get(`${fabricQualities}/top-selling/dropdowns`);
    dispatch(GET_MATERIAL_DATA(res.data.data));
  } catch (err) {
    console.log(err);
    dispatch(
      flash({
        message: err.msg,
      })
    );
  }
};

export const createNewTopQuality = (requestData) => async (dispatch) => {
  try {
    const res = await axios.post(`${fabricQualities}/top-selling`, requestData);
    dispatch(flash({ message: res.data.message }));
    return res.data;
  } catch (err) {
    dispatch(
      flash({
        message: err.response.data.message,
      })
    );
  }
};

export const updateTopQuality = (requestData) => async (dispatch) => {
  try {
    const res = await axios.patch(
      `${fabricQualities}/top-selling`,
      requestData
    );
    dispatch(flash({ message: res.data.message }));
    return res.data;
  } catch (err) {
    dispatch(
      flash({
        message: err.response.data.message,
      })
    );
  }
};
