import React from "react";
import "./style.scss";

const TextAreaField = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  required,
  className,
  ...props
}) => {
  const { name } = field;

  return (
    <div className={`formik-field formik-textarea ${className}`}>
      <label htmlFor={name}>
        {label}
        {required && <sup>*</sup>}
      </label>
      <textarea
        required={required}
        {...field}
        {...props}
      />
      {touched[field.name] && errors[field.name] && (
        <div className="error">{errors[field.name]}</div>
      )}
    </div>
  );
};

export default TextAreaField;
