export const ALL_STATES = [
  'Andaman and Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra and Nagar Haveli',
  'Daman and Diu',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal'
];

export const ORG_TYPES = {
  brand: 2,
  factory: 3,
  fabricCustomer: 4,
  fabricSupplier: 5
};

export const ORG_TYPE_NAMES = {
  2: 'brand',
  3: 'factory'
};

export const VALID_FILE_TYPES =
  'image/png, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const SUPPLIER_CATEGORY_HASH = {
  1: 'Weaving',
  2: 'Processing',
  3: 'Weaving, Processing'
};

export const SUPPLIER_CATEGORY_HASH_KEY = {
  WEAVING: 1,
  PROCESSING: 2,
  WEAVING_PROCESSING: 3
};

export const FABRIC_TYPE_KEY = {
  KNITS: 1,
  WOVEN: 2,
  KNITS_WOVEN: 3
};

export const FABRIC_TYPE = {
  1: 'Knits',
  2: 'Woven',
  3: 'Knits, Woven'
};

export const SUPPLIER_TYPE = {
  1: 'Agent',
  2: 'Asset Owner'
};

export const COUNTRY_TYPE = {
  INDIA: 1,
  INTERNATIONAL: 2
};

export const SUPPLIER_TYPE_KEY = {
  Agent: 1,
  Asset_Owner: 2
};

export const REQUIREMENT_TYPES_KEY = {
  Greige: 1,
  YarnDyed: 2,
  Rfd: 3,
  Dyed: 4,
  Printed: 5,
};

export const REQUIREMENT_TYPES = {
  [REQUIREMENT_TYPES_KEY.Greige]: 'Greige',
  [REQUIREMENT_TYPES_KEY.YarnDyed]: 'Yarn Dyed',
  [REQUIREMENT_TYPES_KEY.Rfd]: 'RFD',
  [REQUIREMENT_TYPES_KEY.Dyed]: 'Dyed',
  [REQUIREMENT_TYPES_KEY.Printed]: 'Printed',
  Asset_Owner: 2,
};

export const MILLS_STATUS = {
  deactivate: 'deactivate',
  activate: 'activate'
};
