import React, { Component } from "react";
import { Field } from "redux-form";
import "./Radio.scss";

class Radio extends Component {
  //   state = {
  //     currentLength: 0,
  //     currentValue: ""
  //   };

  //   static getDerivedStateFromProps(nextProps, prevState) {
  //     return {
  //       ...prevState,
  //       currentLength: nextProps.input.value.length
  //     };
  //   }

  // handleChange = (evt, ...args) => {
  //   this.setState({
  //     currentLength: evt.target.value.length,
  //     currentValue: evt.target.value
  //   });
  //   this.props.input.onChange(evt);
  // };

  render() {
    const {
      label,
      meta: { touched, error, warning },
      meta,
      input,
      data,
      // import separately so that it doesn't override the default HTML onChange
      onChange,
      ...props
    } = this.props;

    return (
      <div className={`input-grp input-grp-radio ${error === true ? "input-grp--error" : ""}`}>
        <div className="flex">
          <label htmlFor={input.name} className="f-sz-sm f-color-faded">
            {label}
            {props.required === true && <sup>*</sup>}
          </label>
          <div className="input-container" style={{ position: "relative" }}>
            {data.map((item, index) => <label className="label-radio" key={index}>
              <Field
                component="input"
                {...input}
                {...props}
                name={input.name}
                type="radio"
                value={item.value}
                // onBlur={this.handleChange}
              />
              <div className="display-value-container">
                <span className="fake-radio"></span>
                <span className="display-value">{item.label}</span>
              </div>
            </label>)}
          </div>
        </div>
        {
          <span className="input-grp__lower flex f-sz-sm">
            {touched &&
              ((error && <span className="error">{error}</span>) ||
                (warning && <span>{warning}</span>))
            }
          </span>
        }
      </div>
    );
  }
}

export default Radio;
