import React, { Component } from "react";

import "./Input.scss";

class Input extends Component {
  state = {
    currentLength: 0,
    currentValue: ""
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      currentLength: (nextProps.input.value && nextProps.input.value.length) || 0,
    };
  }

  handleChange = (evt, ...args) => {
    this.setState({
      currentLength: evt.target.value.length,
      currentValue: evt.target.value
    });
    this.props.input.onChange(evt);
  };

  render() {
    const {
      label,
      meta: { touched, error, warning },
      meta,
      input,
      maxLength,
      suffixContent,
      suffixContentWidth,
      leftAddon = null,
      rightAddon = null,
      // import separately so that it doesn't override the default HTML onChange
      onChange,
      ...props
    } = this.props;
    const { currentLength } = this.state;

    return (
      <div className={`input-grp ${error === true ? "input-grp--error" : ""}`}>
        <label htmlFor={input.name} className="f-sz-sm f-color-faded">
          {label}
          {props.required === true && <sup>*</sup>}
        </label>
        <div className={`input-container ${leftAddon ? "with-left-addon" : ""} ${rightAddon ? "with-right-addon" : ""}`}>
          {leftAddon && <div className="addon">{leftAddon}</div>}
          <input
            {...input}
            {...props}
            id={input.name}
            onBlur={this.handleChange}
            maxLength={maxLength}
            style={{
              ...(this.props.prefixText && {
                paddingLeft: `${this.props.prefixText.length + 2}ch`
              }),
              ...(suffixContentWidth && { paddingRight: suffixContentWidth })
            }}
          />
          {this.props.prefixText && (
            <span
              style={{
                color: "var(--fill-grey-light)",
                position: "absolute",
                left: "10px",
                top: "14px"
              }}
            >
              {this.props.prefixText}
            </span>
          )}
          {rightAddon && <div className="addon">{rightAddon}</div>}
          {suffixContent && (
            <span
              style={{
                position: "absolute",
                right: "10px",
                top: "8px"
              }}
            >
              {suffixContent}
            </span>
          )}
        </div>
        {
          <span className="input-grp__lower flex f-sz-sm">
            {touched &&
              ((error && <span className="error">{error}</span>) ||
                (warning && <span>{warning}</span>))}

            {maxLength && !this.props.disabled ? (
              <span className="input-len-count f-color-faded">
                {currentLength}/{maxLength}
              </span>
            ) : null}
          </span>
        }
      </div>
    );
  }
}

export default Input;
