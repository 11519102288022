import React from 'react';
import BaseEmptyScreen from "components/UI/EmptyScreen"
import CustomersEmpty from 'assets/images/emptyPages/customers-empty.svg';

const EmptyScreen = () => {
  return <BaseEmptyScreen
    icon={CustomersEmpty}
    heading="No mills found"
    description={<>
      Please refresh
    </>}
  />;
};

export default EmptyScreen;
