import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ProfileAvatar } from '../ProfileAvatar';
import { ORG_TYPES } from '../../../constants/Global';
import './style.scss';

export class BrandImage extends ProfileAvatar {
  static propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    borderRadius: PropTypes.string,
    size: PropTypes.string,
    showCompleteName: PropTypes.bool,
    orgType: PropTypes.oneOf(ORG_TYPES),
  };

  static defaultProps = {
    orgType: 'BRAND',
  }

  state = {
    hasError: false,
    url: '',
    bgColor: 'var(--fill-primary-medium)',
  };

  static getDerivedStateFromProps(props, state) {
    const superState = ProfileAvatar.getDerivedStateFromProps(props, state) || {};

    if (state.url !== props.url) {
      return {
        ...superState,
        url: props.url,
        hasError: false
      }
    }

    return superState;
  }


  handleImgError = () => {
    this.setState({ hasError: true });
  }

  render() {
    const { id, name, borderRadius, size, showCompleteName, orgType } = this.props;
    const { hasError, bgColor } = this.state;

    const url = (id && `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_BRAND_LOGO_PATH}/${id}.png`)
      || this.props.url;

    const hasValidImage = url && !hasError;

    return <div className={classNames(`brand-image brand-image--${orgType.toLowerCase()}`, {
      'avatar': !hasValidImage && !showCompleteName,
    })}
      style={{
        backgroundColor: (hasValidImage ? '' : (showCompleteName ? '' : bgColor)),
        borderRadius: borderRadius,
        zoom: (size > 0 ? size / 40 : 1),
      }}
      data-tip={name}
      data-place="bottom">
      {
        hasValidImage
          ? <img className="img" src={url} onError={this.handleImgError} alt="" />
          : (showCompleteName
            ? <div className="full-name">{name}</div>
            : <div className="initials">{this.getInitials(name)}</div>)
      }
    </div>;
  }
}
