import { PropTypes } from "prop-types";
import React from "react";
import "./UploadProgress.scss";
import closeIcon from '../../../assets/images/navigation/ic-close-light.svg'; 
import { withRouter, Link } from 'react-router-dom';
// import closeIcon from "../assets/images/navigation/ic-close-light.svg";

const UploadProgress = ({ fileName="Something went wrong!", isUploading, uploadComplete, pathName, hideProgress, error }) => {
  setTimeout(hideProgress,4000)
  return <div className="progress-msg">
          { isUploading ? `Uploading ${fileName}` : ''}
          {(uploadComplete || error) ? 
            <div>
              <img
                  src={closeIcon}
                  alt="close"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                    cursor: "pointer"
                  }}
                  onClick={hideProgress}
                />
                <div>
                  {
                    error
                    ? error
                    : (<span>File Uploaded Successfully <a href={pathName} className="progress-msg-close">(View)</a></span>)
                  }
                  </div>
            </div> : ''
          }
         </div>;
};

UploadProgress.propTypes = {
  fileName: PropTypes.string.isRequired
};

export default withRouter(UploadProgress);
