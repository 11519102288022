import { handleActions } from "redux-actions";

const INITIAL_STATE = {
    name: '',
    baseUrl: '',
    isActive: '1',
    trackingIdConstraint: ''
};

const LogisticReducer = handleActions(
  {
    GET_PARTNER_DETAILS: (state, action) => {
      return {
        ...action.payload
      };
    },

    RESET_PARTNER_DETAILS: () => {
      return {
        name: '',
        baseUrl: '',
        isActive: '1',
        trackingIdConstraint: ''
      }
    }
  },

  INITIAL_STATE
);

export default LogisticReducer;
