import * as React from "react";

const Status = ({ props, isFilled }) => {
  let colour = "#D4D4D4";
  if (isFilled === true) {
    colour = "#6FCF97";
  }
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="8.00041" cy="8.00016" r="6.66667" fill={colour} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.2723 5.66206C11.5326 5.92241 11.5326 6.34452 11.2723 6.60487L7.53892 10.3382C7.27857 10.5986 6.85646 10.5986 6.59611 10.3382L4.72944 8.47153C4.46909 8.21119 4.46909 7.78908 4.72944 7.52873C4.98979 7.26838 5.4119 7.26838 5.67225 7.52873L7.06751 8.92399L10.3294 5.66206C10.5898 5.40171 11.0119 5.40171 11.2723 5.66206Z"
        fill="white"
      />
    </svg>
  );
};

export default Status;
