
// User statuses has no association with the status column of users table
// (0,1,2) is computed from getUserStatus()
export const USER_STATUSES = [
  {
    id: "inactive",
    name: "Inactive"
  },
  {
    id: "active",
    name: "Active"
  },
  {
    id: "invite-pending",
    name: "Invite Pending"
  }
];

export const getUserStatus = (user) => {
  if (user.last_login || user.lastLogin) {
    return +(user.is_enabled || user.isEnabled) || 0;
  }
  return 2;
}
