import React from 'react';
import classnames from 'classnames';
import { Field } from 'formik';
import * as Yup from "yup";
import Form from 'components/CustomBrand/UI/Formik/Form';
import Input from 'components/CustomBrand/UI/Formik/Input';
import TextArea from 'components/CustomBrand/UI/Formik/TextArea';
import Address from 'components/CustomBrand/UI/Formik/Address';
import InputLogo from 'components/CustomBrand/UI/Formik/InputLogo';
import { ORG_TYPES } from 'constants/Global';

const YUP_ADD_FABRIC_CUSTOMER = Yup.object().shape({
  name: Yup.string().trim()
    .min(1)
    .max(100)
    .required("Required"),
  webUrl: Yup.string().trim()
    .min(1)
    .max(100)
    .required("Required"),
  address: Yup.object().shape({
    addressLines: Yup.string().trim().min(1).max(100).required('Address is required'),
    city: Yup.string().trim().min(1).max(50).required('City is required'),
    state: Yup.string().trim().min(1).max(50).required('State is required'),
    pinCode: Yup.string().trim().min(1).max(10).required('Pin code is required'),
  })
    .required("Required"),
  gstNumber: Yup.string().trim().max(100).nullable(),
  firstName: Yup.string().trim().max(100).required('Required'),
  lastName: Yup.string().trim().max(100).nullable(),
  designation: Yup.string().trim().max(100).required('Required'),
  phone: Yup.string().trim()
    .matches(/^[6-9]\d{9}$/, 'Enter a valid mobile number')
    .nullable(),
  additionalDetails: Yup.string().trim().max(255).nullable(),
});

const YUP_ADD_BRAND_CUSTOMER = Yup.object().shape({
  name: Yup.string().trim()
    .min(1)
    .max(100)
    .required("Required"),
  webUrl: Yup.string().trim()
    .min(1)
    .max(100)
    .required("Required"),
  address: Yup.object().shape({
    addressLines: Yup.string().trim().min(1).max(100).required('Address is required'),
    city: Yup.string().trim().min(1).max(50).required('City is required'),
    state: Yup.string().trim().min(1).max(50).required('State is required'),
    pinCode: Yup.string().trim().min(1).max(10).required('Pin code is required'),
  })
    .required("Required"),
  gstNumber: Yup.string().trim().max(100).nullable(),
});

const CustomerForm = ({
  customer,
  customerType,
  editMode = false,
  onValid,
  onChange,
}) => {
  return (<Form
    initialValues={customer}
    onValidationChange={onValid}
    validationSchema={customerType === ORG_TYPES.brand ? YUP_ADD_BRAND_CUSTOMER : YUP_ADD_FABRIC_CUSTOMER}
    validateOnMount={true}
    onFormChange={onChange}
  >
    {() => <div
      className={classnames("customer-form", {
        'customer-form--editing': editMode,
      })}
    >
      <div className="col">
        <Field
          component={Input}
          name="name"
          type="text"
          label="Customer name"
          placeholder="E.g. Zara Clothing Pvt. Ltd."
          required
          autoFocus
        />
        <Field
          component={Input}
          name="gstNumber"
          type="text"
          label="GSTIN (Optional)"
          placeholder="Enter GSTIN"
        />
        <Field
          component={Input}
          name="webUrl"
          type="text"
          label="Website"
          placeholder="zara.com"
          required
        />
        {customerType === ORG_TYPES.fabricCustomer
          && <>
            <div className="formik-field grid-2 col-equal">
              <Field
                component={Input}
                name="firstName"
                type="text"
                label="Contact person name"
                placeholder="First name"
                required
              />
              <Field
                component={Input}
                name="lastName"
                type="text"
                placeholder="Last name"
                required
              />
            </div>
            <Field
              component={Input}
              name="designation"
              type="text"
              label="Designation"
              placeholder="Enter designation"
              required
            />
            <Field
              component={Input}
              name="phone"
              type="text"
              label="Phone number (Optional)"
              placeholder="Enter phone number"
            />
          </>}
      </div>
      <div className="col">
        <Field
          component={InputLogo}
          name="logo"
        />
        <Field
          component={Address}
          name="address"
          label="Shipping address"
          placeholder="Enter shipping address"
          required
        />
        {customerType === ORG_TYPES.fabricCustomer && <Field
          component={TextArea}
          name="additionalDetails"
          label="Additional details"
          placeholder="Enter additional details"
          className="additional-details"
        />}
      </div>
    </div>}
  </Form>);
};

export default CustomerForm;
