import React from 'react';
import classnames from 'classnames';
import { TabPanel } from 'components/UI/Tabs';
import { ProfileAvatar } from 'components/UI/ProfileAvatar';
import Button from 'components/UI/Button/Button';
import { IconAddAvatar } from 'components/UI/Icons';
import { getUserStatus, USER_STATUSES } from 'helpers/userHelpers';
import { USER_ROLES } from 'constants/Auth';
import './style.scss';

const OrgUsersTabPanel = ({
  header,
  users,
  onAdd,
  ctas,
  gridTemplateColumns,
}) => {
  return (<TabPanel
    header={header}
  >
    <div
      className="org-users-tab-panel"
    >
      <div className="org-users-row">
        <Button
          category="plain-blue grid-2 shrink"
          className="cta-add"
          type="button"
          onClick={onAdd}
        >
          <IconAddAvatar color="blue" />
          Add New
        </Button>
      </div>
      {users.map((user) => {
        const userStatus = USER_STATUSES[getUserStatus(user)];

        return (<div
          className="org-users-row org-users-item"
          key={user.id}
          style={{
            gridTemplateColumns,
          }}
        >
          <div className="user-profile">
            <ProfileAvatar
              url={user.image}
              name={user.first_name}
              id={user.id}
            />
            <div className="cell-v2">
              <div>{user.name}</div>
              <div>{USER_ROLES[user.role]}</div>
            </div>
          </div>
          <div className="user-contact cell-v2">
            <div>{user.email}</div>
            <div>{user.phone}</div>
          </div>
          <div className={classnames("user-status", userStatus.id)}>
            {userStatus.name}
          </div>

          {/* Render CTAs is any  */}
          {ctas(user)}

        </div>)
      })}
    </div>
  </TabPanel>);
};

export default OrgUsersTabPanel;
