import React from "react";
import { PageHeader, Button, Modal, Radio, Input, Select } from "antd";
import { ACTIVATION_STATUS } from "../../constants";
import "./styles.scss";
import StatusToggler from 'components/UI/Toggler/StatusToggler';

const CustomerDetailHeader = ({
  customer,
  listOfAM,
  updateAccountManager,
  currentUser,
  deactivateCustomer,
}) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [showAmEditModal, setShowAmEditModal] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [selectedAM, setSelectedAM] = React.useState(
    customer?.assignedAccountManagerName || {}
  );
  const [comment, setComment] = React.useState("");

  const onChange = (e) => {
    setValue(e.target.value);
  };
  const onCommentChange = (e) => {
    setComment(e.target.value);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const showAmModal = () => {
    setShowAmEditModal(true);
  };
  const handleOk = () => {
    deactivateCustomer({
      customerId: customer.id,
      deactivationComment: value === "other" ? comment : value,
      userId: currentUser.id,
      actionType: customer?.activationStatus === ACTIVATION_STATUS.activated ? "deactivate" : "activate",
    });
    setIsModalVisible(false);
  };
  const handleAmOk = () => {
    updateAccountManager({
      ...selectedAM,
      customerId: customer.id,
      actionType: "editAM",
      userId: currentUser.id,
    });
    setShowAmEditModal(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleAmCancel = () => {
    setShowAmEditModal(false);
  };
  function onSelectChange(e, id) {
    setSelectedAM({ newAccountManagerId: +id.key });
  }
  function showDeactivationReason(){
    if(customer?.activationStatus === ACTIVATION_STATUS.deactivated){
      return `Deactivated due to: ${customer.deactivationComment}`;
    }
    return '';
  }

  const { Option } = Select;
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={customer && customer.name}
        extra={[
          <span>Account Manager:</span>,
          <span>{customer?.assignedAccountManagerName}</span>,
          <Button type="link" onClick={showAmModal}>
            Edit AM
          </Button>,
          <Modal
            title="Edit Account Manager"
            visible={showAmEditModal}
            onOk={(e) => handleAmOk(e)}
            onCancel={handleAmCancel}
            width={500}
          >
            <span>
              <h3>Select New Account Manager</h3>
            </span>
            <Select
              placeholder="Select a person"
              optionFilterProp="children"
              onChange={(e, id) => onSelectChange(e, id)}
              className="dropdown-select"
              defaultValue={customer?.assignedAccountManagerName}
              filterOption={(input, option) =>
                option?.children
                  ?.toLowerCase()
                  ?.indexOf(input?.toLowerCase()) >= 0
              }
              showSearch={true}
            >
              {listOfAM.map((am) => (
                <Option key={am.id} value={am.name}>
                  {am.name}
                </Option>
              ))}
            </Select>
          </Modal>,
          <StatusToggler
            label={'Status'}
            condition={customer?.activationStatus === ACTIVATION_STATUS.activated}
            onFalseLabel={'Deactivate'}
            onTrueLabel={'Activate'}
            onChange={() => showModal()}
          />,
          <Modal
            title={`${
              customer?.activationStatus === ACTIVATION_STATUS.activated ? "Deactivate" : "Activate"
            } ${customer?.name}`}
            visible={isModalVisible}
            onOk={(e) => {(customer?.activeOrders === 0 && customer?.isInternational === 0) && handleOk(e)}}
            onCancel={handleCancel}
            okText={`${
              customer?.activationStatus === ACTIVATION_STATUS.activated ? "Deactivate" : "Activate"
            }`}
          >
            {customer?.activationStatus === ACTIVATION_STATUS.deactivated ? (
              <div>
                <h2>{`Please confirm to activate ${customer?.name}`}</h2>
              </div>
            ) : (
              <div>
                {customer?.isInternational === 1 && (
                  <h2>International customer cannot be deactivated</h2>
                )}
                {customer?.activeOrders > 0 &&
                  customer?.isInternational === 0 && (
                    <h2>{`${customer?.name} has active orders. You cannot deactivate ${customer?.name}.`}</h2>
                  )}
                {customer?.openRequests > 0 && customer?.isInternational === 0 && (
                  <h2>
                    {`Customer has ${customer?.openRequests} active queries.`}
                    <br /> Confirm Deactivate ?
                  </h2>
                )}
                {customer?.activeOrders === 0 &&
                  customer?.isInternational === 0 && (
                    <>
                      <p>Deactivating due to </p>
                      <Radio.Group onChange={onChange} value={value}>
                        <Radio value="Incorrect Customer Details">
                          Incorrect Customer Details
                        </Radio>
                        <br />
                        <Radio value="Payment Issues">Payment Issues</Radio>
                        <br />
                        <Radio value="other">
                          Other
                          {value === "other" ? (
                            <Input
                              className="commentBox"
                              placeholder="Enter other reason"
                              value={comment || ""}
                              onChange={onCommentChange}
                            />
                          ) : null}
                        </Radio>
                      </Radio.Group>
                    </>
                  )}
              </div>
            )}
          </Modal>,
        ]}
      >
        {showDeactivationReason()}
      </PageHeader>
    </div>
  );
};

export default CustomerDetailHeader;
