import { getCurrentUserRole } from "./AuthHelper";
import {
  LOCO_SUPER,
  FACTORY_ADMIN,
  FACTORY_MANAGER,
  CLIENT,
  CLIENT_SUPER,
  PRODUCT_DEVELOPMENT,
  PRODUCTION_MERCHANT
} from "../constants/Auth";

export const getHeaderText = path => {
  const regex = new RegExp(
    /(collections|sampling|production|messages|orders|settings|swatchLibrary|notifications|quotes|dashboard|library|reports|performance)/gi
  );

  const headings = {
    collections: "Collections",
    sampling: "Sampling",
    production: "Production",
    messages: "Messages",
    orders: "Orders",
    settings: "Settings",
    swatchLibrary: "Swatch Library",
    notifications: "Email Notifications Dashboard",
    quotes: "Quotes",
    dashboard: "Dashboard",
    library: "Material Library",
    reports: "Reports",
    performance: "Performance Report"
  };
  const regexTest = regex.exec(path);
  if (regexTest && regexTest.length > 0) {
    return headings[regexTest[0]];
  }

  return null;
};

export const computePath = () => {
  const role = getCurrentUserRole();
  switch (role) {
    case LOCO_SUPER:
    case FACTORY_ADMIN:
    case FACTORY_MANAGER:
      return "factory";
    case CLIENT:
    case CLIENT_SUPER:
      return "brand";
    case PRODUCT_DEVELOPMENT:
      return "manager";
    case PRODUCTION_MERCHANT:
      return "merchant";
    default:
      return "factory";
  }
};

export const constructPath = (brandId) => {
  const role = getCurrentUserRole();
  switch (role) {
    case LOCO_SUPER:
    case FACTORY_ADMIN:
    case FACTORY_MANAGER:
      return `factory/brands/${brandId}`;
    case CLIENT:
    case CLIENT_SUPER:
      return "brand";
    case PRODUCT_DEVELOPMENT:
      return "manager";
    case PRODUCTION_MERCHANT:
      return `merchant/brands/${brandId}`;
    default:
      return "factory";
  }
}
