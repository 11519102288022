import { PropTypes } from "prop-types";
import React, { Component } from "react";
import "./ManageLfUsers.scss";
import ManageUsersTable from "./ManageLfUsersTable";
import Headers from "./Headers";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { PAGE_TITLES } from "constants/Titles";
import { ALL_LF_ROLES } from "constants/Filters";
class ManageLfUsers extends Component {
  state = {
    filters: [ALL_LF_ROLES],
    isUpdated: false,
  };

  componentDidMount() {
    this.props.getLocofastUsers(this.state.filters);
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevState.isUpdated !== this.state.isUpdated) {
      this.props.getLocofastUsers(this.state.filters);
      if (this.state.isUpdated) {
        this.setState({ isUpdated: false })
      }
    }
  }


  handleFilterChange = (selection) => {
    this.setState({
      filters: selection,
    }, () => {
      this.props.getLocofastUsers(this.state.filters);
    });
  };

  static propTypes = {
    usersList: PropTypes.array.isRequired,
    openModal: PropTypes.func.isRequired,
    getLocofastUsers: PropTypes.func.isRequired,
    addLfUser: PropTypes.func.isRequired,
    updateLfUser: PropTypes.func.isRequired,
  };

  setIsUpdated = (value) => {
    this.setState({ isUpdated: value })
  }

  render() {
    const {
      openModal,
      addLfUser,
      updateLfUser,
      getLocofastUsers,
      linkZohoToUser,
      updateLinkZohoToUser,
      userDetails,
      usersList,
    } = this.props;
    return (
      <>
        <Helmet title={PAGE_TITLES.settingsLFUsers()} />
        <div className="manage-lfusers">
          <Headers
            openModal={openModal}
            addLfUser={addLfUser}
            linkZohoToUser={linkZohoToUser}
            getLocofastUsers={getLocofastUsers}
            onChange={this.handleFilterChange}
            loggedInUserDetails={userDetails}
          />
          <ManageUsersTable
            usersList={usersList}
            updateLfUser={updateLfUser}
            openModal={openModal}
            updateLinkZohoToUser={updateLinkZohoToUser}
            linkZohoToUser={linkZohoToUser}
            loggedInUserDetails={userDetails}
            setIsUpdated={this.setIsUpdated}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  usersList: state.auth.lfUsers || [],
  userDetails: state.auth.userDetails,
});

export default connect(mapStateToProps, null)(ManageLfUsers);
