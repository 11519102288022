import { Switch } from "antd";
import React, { useState } from "react";
import style from "./style.module.scss";
import { updateTopQuality } from "store/actions/FabricQualitiesActions.js";

import { connect } from "react-redux";

const ToggleSwitch = ({ record, reloader, updateTopQuality }) => {
  const [active, setActive] = useState(record?.isDisable ? false : true);
  const form = {
    id: record.id,
    isDisable: active ? 1 : 0,
  };
  const toggle = () => {
    setActive(!active);
    setTimeout(() => {
      updateTopQuality(form).then((res) => {
        if (res) {
          reloader();
          setActive(record?.isDisable ? false : true);
        }
      });
    }, "500");
  };
  return (
    <div className={style.switch}>
      <div>{active ? "On" : "Off"}</div>
      <Switch onClick={() => toggle()} checked={active} />
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  updateTopQuality: (data) => dispatch(updateTopQuality(data)),
});
export default connect(null, mapDispatchToProps)(ToggleSwitch);
