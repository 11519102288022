import PropTypes from "prop-types";
import React from "react";
import { Link, withRouter } from "react-router-dom";

import "./Sidebar.scss";
import ReactTooltip from "react-tooltip";

const Sidebar = props => {
  const currentPath = props.location.pathname;

  return (
    <div className="sidebar-custom__wrapper">
      <div className="sidebar-custom__items flex-col">
        <div className="sidebar-custom__uppper">
          {props.upperItems.map(({ id, name, img: Icon, link, baseRoute = name }) => (
            <Link
              key={name}
              to={link ? link : "#"}
              disabled={!link}
              className={
                link &&
                  (currentPath.includes(link) ||
                    currentPath.includes(baseRoute.toLowerCase()))
                  ? `sidebar-custom__link sidebar-link-${id} active`
                  : `sidebar-custom__link sidebar-link-${id}`
              }
              onClick={() => {
                ReactTooltip.hide();
              }}
            >
              <div
                className="sidebar-custom__item f-sz-xs"
                data-tip={name}
                data-place="right"
                data-offset="{'left': 20}"
              >
                <Icon className="sidebar-custom__img" alt={name} />
              </div>
            </Link>
          ))}
        </div>
        <div className="sidebar-custom__lower">
          {props.lowerItems.map(({ id, name, img: Icon, link, onClick }) => (
            link
              ? <Link
                key={name}
                to={link}
                className={
                  link &&
                    (currentPath.includes(link) ||
                      currentPath.includes(name.toLowerCase()))
                    ? `sidebar-custom__link sidebar-link-${id} active`
                    : `sidebar-custom__link sidebar-link-${id}`
                }
              >
                <div
                  className="sidebar-custom__item f-sz-xs"
                  data-tip={name}
                  data-place="right"
                  data-offset="{'left': 20}"
                >
                  <Icon className="sidebar-custom__img" alt={name} />
                </div>
              </Link>
              : <a
                key={name}
                onClick={onClick}
                className={`sidebar-custom__link sidebar-link-${id} clickable`}
              >
                <div
                  className="sidebar-custom__item f-sz-xs"
                  data-tip={name}
                  data-place="right"
                  data-offset="{'left': 20}"
                >
                  <Icon className="sidebar-custom__img" alt={name} />
                </div>
              </a>
          ))}
        </div>
      </div>

      <div />
    </div>
  );
};

Sidebar.propTypes = {
  upperItems: PropTypes.array,
  lowerItems: PropTypes.array,
  match: PropTypes.object,
  location: PropTypes.object
};

Sidebar.defaultProps = {
  upperItems: [],
  lowerItems: []
};

export default withRouter(Sidebar);
