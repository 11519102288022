import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import moment from 'moment';
import PerfectScrollBar from "react-perfect-scrollbar";

import Button from "./../../UI/Button/Button";
import { ProfileAvatar } from '../../UI/ProfileAvatar';
import { USER_ROLES } from '../../../constants/Auth';
import { USER_STATUSES, getUserStatus } from '../../../helpers/userHelpers';

import emptyIcon from "assets/img/User/manage-users.svg";
import plusIcon from "assets/images/content/ic-plus.png";

import "./ManageUsers.scss";
import { Helmet } from "react-helmet";
import { PAGE_TITLES } from "../../../constants/Titles";

import { checkSuperAdmin } from "../../../helpers/AuthHelper";

class ManageUsers extends Component {
  componentDidMount() {
    if(Object.keys(this.props.usersList).length === 0){
      this.props.getUsers();
    }
  }

  static propTypes = {
    usersList: PropTypes.array.isRequired,
    openModal: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired
  };

  /**
   *
   * @param {User|undefined} user {...} or null
   */
  handleAddEditUsers = (user) => {
    const isEdit = !!(user && user.id);
    this.props.openModal(isEdit ? "EDIT_USER" : "ADD_USER", {
      initialValues: {
        ...user,
        status: true//((!user || user.status === undefined) ? true : user.status)
      }
    });
  }

  renderHeader() {
    return <div className="header">
      <div className="header-row">
        <div style={{ fontSize: "24px", fontWeight: 600, flex: 1 }}>
          Users
        </div>
        <div className="actions">
          <Button type="button" category="blue-bg" onClick={this.handleAddEditUsers.bind(this)}>
            Add User
          </Button>
        </div>
      </div>
      <div className="header-row">
        <div className="col-headers">
          <div className="col-header">Name</div>
          <div className="col-header">Contact</div>
          <div className="col-header">Last Activity</div>
          <div className="col-header">Status</div>
        </div>
      </div>
    </div>
  }

  renderUsersList(usersList) {
    return <PerfectScrollBar>
      <div className="users-list-container">
        {usersList.map(user => this.renderUserRow(user))}
      </div>
    </PerfectScrollBar>;
  }

  renderUserRow(user) {
    const {
      id,
      name,
      image,
      role: userRole,
      email,
      phone,
      last_login,
      is_superadmin
    } = user;
    const status = getUserStatus(user);
    const userStatus = USER_STATUSES[status] || {};
    const userRoleName = is_superadmin === 1 ? 'Super Admin' : USER_ROLES[userRole];
    const isLocoAdmin = checkSuperAdmin();

    return <div className="user-row" key={id}>
      <div className="profile">
        <div className="flex">
          <ProfileAvatar id={id} name={name} url={image} initialLength={2}></ProfileAvatar>
          <div className="flex flex-col flex-v-center">
            <span className="name">{name}</span>
            <div className="user-role f-color-faded">{userRoleName}</div>
          </div>
        </div>
      </div>
      <div className="contact">
        <div className="email">{email}</div>
        <div className="phone">{phone}</div>
      </div>
      <div className="last-activity">
        <div className="last-login">{
          last_login
            ? moment(last_login).fromNow(true)
            : <span className="f-color-faded">N/A</span>
        }</div>
      </div>
      <div className="status">
        <div className="flex">
          <div className={`user-status ${userStatus.id}`}>{userStatus.name}</div>
          {(isLocoAdmin || is_superadmin !== 1) && (
            <div className="actions">
              <i className="fa fa-pencil" onClick={this.handleAddEditUsers.bind(this, user)}></i>
            </div>
          )}
        </div>
      </div>
    </div>
  }

  renderEmptyList = () => (
    <div className="empty-list-container empty-list-container--users-list">
      <div className="img-container">
        <img src={emptyIcon} alt="Empty! Add factory" />
      </div>
      <div className="content">
        <div className="heading">Manage users</div>
        <div className="description">
          Add users to your organization. <br />
          We'll send them an invite.
        </div>
        <div className="cta">
          <Button category="blue-bg grid-2" onClick={() => this.handleAddEditUsers()}>
            <img src={plusIcon} alt=''/>
            <span> Add User</span>
          </Button>
        </div>
      </div>
    </div>
  );

  render() {
    // Filter out the currently loggedIn user from the list.
    const usersList = this.props.usersList.filter(
      user => user.id !== this.props.currentUser.id
    );

    return (<>
      <Helmet
        title={PAGE_TITLES.settingsUsers()}
      />
      {usersList.length > 0
        ? <div className="manage-users">
          {this.renderHeader()}
          {this.renderUsersList(usersList)}
        </div>
        : <div className="manage-users">
          {this.renderEmptyList()}
        </div>
      }
    </>
    );
  }
}

export default ManageUsers;
