export const SM = 'sm'
export const AM = 'am'

export const DATE_FORMAT_PATTERN = 'YYYY-MM-DD[T]HH:mm:ss[Z]'

export const MAP_TAB_URL_COUNT = {
  'am': 'accountManagers',
  'sm': 'supplierManagers'
};

export const TABS = {
  am: {
    name: 'Account Manager',
    tabUrl: AM,
  },
  sm: {
    name: 'Supplier Manager',
    tabUrl: SM,
  },
};

export const RESULTS_PER_PAGE_LIMIT = 10

export const QUICK_MENU_OPTIONS = ['yesterday', 'past 14 days', 'past 7 days', 'past 30 days']