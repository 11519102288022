import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import ProgressSteps from 'components/UI/ProgressSteps';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import ChooseTypePanel from './ChooseTypePanel';
import CustomerForm from './CustomerForm';
import { IconArrowChevron } from 'components/UI/Icons';
import { getCustomerById } from 'store/actions/UserActions';
import './style.scss';

const AddCustomerModal = ({
  closeModal,
  customer,
  customerId,
  getCustomerById,
  editMode = false,
  onSubmit,
}) => {
  const [progressStepIndex, setProgressStepIndex] = useState(editMode ? 1 : 0);
  const [selectedCustomerType, setSelectedCustomerType] = useState((customer && customer.type) || 0);
  const [customerFormData, setCustomerFormData] = useState(editMode ? null : customer);
  const [formValid, setFormValid] = useState(false);
  const canSubmit = useMemo(() => {
    if (progressStepIndex === 0) {
      return selectedCustomerType !== 0;
    }

    return formValid;
  }, [selectedCustomerType, progressStepIndex, formValid]);

  useEffect(() => {
    if (!editMode) {
      return;
    }

    getCustomerById({ id: customerId });
  }, [customerId, editMode, getCustomerById]);

  useEffect(() => {
    if (!editMode) {
      return;
    }

    setCustomerFormData(customerFormData => {
      if (customerFormData && customerFormData.firstName) {
        return customerFormData;
      }

      return {
        ...customer,
        logo: {
          imgUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_BRAND_LOGO_PATH}/${customer.id}.png`,
          name: `${customer.id}.png`,
        },
        address: {
          addressLines: (customer.addressLine1 || '') + '\n' + (customer.addressLine2 || ''),
          state: { label: customer.state, value: customer.state },
          city: customer.city,
          pinCode: customer.pinCode,
        }
      };
    });
  }, [customer, editMode]);

  // useEffect(() => {
  //   setCustomerFormData(customer);
  // }, [customer]);

  const stepsClickHandler = useCallback((stepNum) => {
    setProgressStepIndex(stepNum);
  }, []);

  const typeSelectHandler = useCallback((type) => {
    setSelectedCustomerType(type);
  }, []);

  const submitHandler = useCallback(() => {
    if (progressStepIndex === 0) {
      setProgressStepIndex(1);
      return;
    }

    onSubmit({
      ...customerFormData,
      address: {
        ...customerFormData.address,
        address_line: customerFormData.address.addressLines,
        state: customerFormData.address.state.value,
      },
      type: selectedCustomerType,
    });
  }, [customerFormData, onSubmit, progressStepIndex, selectedCustomerType]);

  return <div
    className={classnames("add-customer-modal", {
      'add-customer-modal--editing': editMode,
    })}
  >
    <ProgressSteps
      activeIndex={progressStepIndex}
      handleClick={stepsClickHandler}
      panels={[
        {
          heading: editMode
            ? 'View type'
            : 'Choose type',
          content: <ChooseTypePanel
            onSelect={typeSelectHandler}
            selectedValue={selectedCustomerType}
            disabled={editMode}
          />,
        },
        {
          heading: "Customer details",
          content: (!editMode || (customerFormData && customerFormData.firstName !== undefined))
            ? <CustomerForm
              editMode={editMode}
              customer={customerFormData}
              customerType={selectedCustomerType}
              onValid={setFormValid}
              onChange={(values) => setCustomerFormData(values)}
            />
            : null,
          disabled: !canSubmit
        },
      ]}
    />
    <ModalFooter
      // cancel="Dismiss"
      submit={
        progressStepIndex === 0
          ? <>
            <span>NEXT</span>
            <IconArrowChevron color="white" />
          </>
          : editMode
            ? 'EDIT CUSTOMER'
            : 'ADD CUSTOMER'
      }
      canSubmit={canSubmit}
      // canCancel={true}
      // onCancel={closeModal}
      submitCategory="blue-bg grid-2"
      onSubmit={submitHandler}
    />
  </div>;
};

const mapStateToProps = (state, props) => ({
  customer: props.customer || state.user.customers.data.find(c => c.id === props.customerId),
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerById: (data) => dispatch(getCustomerById(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomerModal);
