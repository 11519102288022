import React, { useEffect, useState } from 'react';
import { Modal, Button, Input, Radio, DatePicker } from 'antd';
import { updateMillDetails } from 'store/actions/UserActions';
import { connect } from 'react-redux';
import './styles.scss';
import FileUpload from 'components/HOCs/FileUpload';
import { omit } from 'lodash';
import { SUPPLIER_TYPE, SUPPLIER_TYPE_KEY } from 'constants/Global';
import moment from 'moment';

const Heading = ({ label, data, edit = true, updateMillDetails }) => {
  const [modal, setModal] = useState(false);
  const [millData, setMillData] = useState({});
  useEffect(() => {
    if (data?.id) {
      setMillData(data);
    }
  }, [data, modal]);

  const handleClick = () => {
    setModal(!modal);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const addresses = { ...millData?.addresses[0] };
    const { addressLine1 = '', addressLine2 = '' } = addresses;
    delete addresses?.addressLine1;
    delete addresses?.addressLine2;
    const request = {
      ...millData,
      gstCertificate: millData.gstCertificate?.url || millData.gstCertificate,
      cancelledCheque: millData.cancelledCheque?.url || millData.cancelledCheque,
      annualTurnoverDocs: millData.annualTurnoverDocs?.url || millData.annualTurnoverDocs,
      annualTurnoverDate: moment(millData.annualTurnoverDate).toISOString(),
      registeredAddress: JSON.stringify({
        address_line: `${addressLine1}\n${addressLine2}`,
        ...addresses,
      }),
      addresses: JSON.stringify(millData?.addresses),
      unit: JSON.stringify(millData?.unit),
      fabricCategory: JSON.stringify(millData?.fabricCategory.map((data) => data.categoryId)),
      fabricCategories: JSON.stringify(millData?.fabricCategory.map((data) => data.categoryId)),
      productionTypes: JSON.stringify(millData?.productionTypes.map((data) => data.id)),
      loomTypes: JSON.stringify(millData?.loomTypes.map((data) => data.id)),
      certifications: JSON.stringify(millData?.certifications.map((data) => data.id)),
      supplierManager: JSON.stringify(millData?.supplierManager),
    };

    const ommittedRequestData = omit(request, [
      'isCertified',
      'isOnline',
      'isVerified',
      'isActive',
    ]);

    Object.keys(ommittedRequestData).forEach((key) => {
      if (!request[key]) {
        delete request[key];
      } else {
        formData.append(key, request[key]);
      }
    });
    updateMillDetails({ formData, supplierId: millData.supplierId });
    setModal(!modal);
  };
  if (millData.id) {
    return (
      <div className="card-heading">
        <div>{label}</div>
        {edit && (
          <div>
            <button className="millEditBtn" onClick={handleClick}>
              Edit
            </button>
          </div>
        )}
        <>
          <Modal
            title={`Edit ${label}`}
            visible={modal}
            onOk={handleSubmit}
            onCancel={handleClick}
            okButtonProps={{ disabled: true }}
            cancelButtonProps={{ disabled: false }}
            footer={[
              <Button key="back" onClick={handleClick}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" role="submit" onClick={handleSubmit}>
                Update
              </Button>,
            ]}>
            {label === 'Basic Details' && (
              <>
                <div className="modalInpContainer">
                  <label>Supplier Type</label>
                  <div className="radioGroup">
                    <Radio.Group
                      value={millData.supplierType}
                      onChange={(e) =>
                        setMillData({
                          ...millData,
                          supplierType: e.target.value,
                        })
                      }
                      className="radio_button">
                      <Radio value={SUPPLIER_TYPE_KEY.Agent}>
                        {SUPPLIER_TYPE[SUPPLIER_TYPE_KEY.Agent]}
                      </Radio>
                      <Radio value={SUPPLIER_TYPE_KEY.Asset_Owner}>
                        {SUPPLIER_TYPE[SUPPLIER_TYPE_KEY.Asset_Owner]}
                      </Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className="modalInpContainer">
                  <label>First Name</label>
                  <Input
                    placeholder="Contact Person First Name"
                    value={millData?.firstName}
                    onChange={(e) => setMillData({ ...millData, firstName: e.target.value })}
                  />
                </div>
                <div className="modalInpContainer">
                  <label>Last Name</label>
                  <Input
                    placeholder="Contact Person Second Name"
                    value={millData?.lastName}
                    onChange={(e) => setMillData({ ...millData, lastName: e.target.value })}
                  />
                </div>
                <div className="modalInpContainer">
                  <label>Phone</label>
                  <Input
                    placeholder="Phone Number"
                    value={millData?.contactNumber}
                    onChange={(e) =>
                      setMillData({
                        ...millData,
                        contactNumber: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="modalInpContainer">
                  <label>Email</label>
                  <Input
                    placeholder="Email ID"
                    value={millData?.email}
                    onChange={(e) => setMillData({ ...millData, email: e.target.value })}
                  />
                </div>
                <div className="modalInpContainer">
                  <label>Company Website</label>
                  <Input
                    placeholder="Company Website"
                    value={millData?.website}
                    onChange={(e) => setMillData({ ...millData, website: e.target.value })}
                  />
                </div>

                <div className="modalInpContainer">
                  <label>GST Certificate</label>
                  <FileUpload
                    file={millData.gstCertificate}
                    setFile={(e) => setMillData({ ...millData, gstCertificate: e })}
                    name="gstCertificate"
                    existingFileName={millData?.gstCertificate?.url || null}
                  />
                </div>
              </>
            )}

            {label === 'Account details' && (
              <>
                <div className="modalInpContainer">
                  <label>Account Number</label>
                  <Input
                    placeholder="Account Number"
                    value={millData.accountNumber}
                    onChange={(e) =>
                      setMillData({
                        ...millData,
                        accountNumber: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="modalInpContainer">
                  <label>IFSC Code</label>
                  <Input
                    placeholder="IFSC Code"
                    value={millData?.ifsc}
                    onChange={(e) => setMillData({ ...millData, ifsc: e.target.value })}
                  />
                </div>

                <div className="modalInpContainer">
                  <label>Bank Name</label>
                  <Input
                    placeholder="Bank Name"
                    value={millData?.bankName}
                    onChange={(e) => setMillData({ ...millData, bankName: e.target.value })}
                  />
                </div>

                <div className="modalInpContainer">
                  <label>Payable days (Calendar days)</label>
                  <Input
                    placeholder="Enter Payable days"
                    min={0}
                    max={100}
                    type="number"
                    value={millData?.creditPeriod}
                    onChange={(e) => setMillData({ ...millData, creditPeriod: e.target.value })}
                  />
                </div>

                <div className="modalInpContainer">
                  <label>Cancelled Cheque</label>
                  <FileUpload
                    file={millData.cancelledCheque}
                    setFile={(e) => setMillData({ ...millData, cancelledCheque: e })}
                    name="cancelledCheque"
                    existingFileName={millData?.cancelledCheque?.url || null}
                  />
                </div>

                <div className="modalInpContainer">
                  <label>Payment Terms</label>
                  <Input
                    placeholder="Payment Terms"
                    value={millData?.paymentTerms}
                    onChange={(e) => setMillData({ ...millData, paymentTerms: e.target.value })}
                  />
                </div>

                <div className="modalInpContainer">
                  <label>Annual Turnover (in Crores)</label>
                  <Input
                    placeholder="Annual Turnover"
                    value={millData?.annualTurnover}
                    onChange={(e) =>
                      setMillData({
                        ...millData,
                        annualTurnover: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="modalInpContainer">
                  <label>Annual Turnover Document</label>
                  <FileUpload
                    file={millData.annualTurnoverDocs}
                    setFile={(e) => setMillData({ ...millData, annualTurnoverDocs: e })}
                    name="annualTurnoverDocs"
                    existingFileName={millData?.annualTurnoverDocs?.url || null}
                  />
                </div>
                <div className="modalInpContainer dateInput">
                  <label>Annual Turnover Date</label>
                  <DatePicker
                    className="date"
                    placeholder="Select Date"
                    value={millData.annualTurnoverDate ? moment(millData.annualTurnoverDate) : null}
                    disabledDate={(current) => {
                      const start = moment().subtract(2, 'years');
                      return current < start || current > moment();
                    }}
                    onChange={(event) => {
                      setMillData({ ...millData, annualTurnoverDate: event });
                    }}
                  />
                </div>
              </>
            )}

            {label === 'Address' && (
              <>
                <div className="modalDetailsContainer">
                  <label>Address Line 1</label>
                  <Input
                    placeholder="Address Line 1"
                    required={true}
                    value={millData?.addresses[0].addressLine1}
                    onChange={(e) => {
                      const updatedAdresses = [
                        {
                          ...millData?.addresses[0],
                          addressLine1: e.target.value,
                        },
                      ];
                      setMillData({ ...millData, addresses: updatedAdresses });
                    }}
                  />
                </div>
                <div className="modalDetailsContainer">
                  <label>Address Line 2</label>
                  <Input
                    placeholder="Address Line 2"
                    value={millData?.addresses[0].addressLine2}
                    onChange={(e) => {
                      const updatedAdresses = [
                        {
                          ...millData?.addresses[0],
                          addressLine2: e.target.value,
                        },
                      ];

                      setMillData({ ...millData, addresses: updatedAdresses });
                    }}
                  />
                </div>

                <div className="modalInpContainer">
                  <label>Landmark</label>

                  <Input
                    placeholder="Landmark"
                    value={millData?.addresses[0].landmark}
                    onChange={(e) => {
                      const updatedAdresses = [
                        { ...millData?.addresses[0], landmark: e.target.value },
                      ];

                      setMillData({ ...millData, addresses: updatedAdresses });
                    }}
                  />
                </div>

                <div className="modalInpContainer">
                  <label>Pin Code</label>
                  <Input
                    placeholder="Pin Code"
                    value={millData?.addresses[0].pinCode}
                    onChange={(e) => {
                      const updatedAdresses = [
                        { ...millData?.addresses[0], pinCode: e.target.value },
                      ];

                      setMillData({ ...millData, addresses: updatedAdresses });
                    }}
                  />
                </div>

                <div className="modalInpContainer">
                  <label>City</label>
                  <Input
                    placeholder="City"
                    value={millData?.addresses[0].city}
                    onChange={(e) => {
                      const updatedAdresses = [{ ...millData?.addresses[0], city: e.target.value }];

                      setMillData({ ...millData, addresses: updatedAdresses });
                    }}
                  />
                </div>

                <div className="modalInpContainer">
                  <label>State</label>
                  <Input
                    placeholder="State"
                    value={millData?.addresses[0].state}
                    onChange={(e) => {
                      const updatedAdresses = [
                        { ...millData?.addresses[0], state: e.target.value },
                      ];

                      setMillData({ ...millData, addresses: updatedAdresses });
                    }}
                  />
                </div>
              </>
            )}
          </Modal>
        </>
      </div>
    );
  } else
    return (
      <div className="card-heading">
        <div>{label}</div>
      </div>
    );
};

export const DeactivateMillModal = ({ visible, toggle, data, action }) => {
  const [reason, setReason] = useState("");
  const [value, setValue] = React.useState("");
  const onChangeValue = (e) => {
    setValue(e.target.value);
  };
  const handleSubmit = () => {
    const newStatus = 'deactivate';
    action({
      supplierId: data?.supplierId.toString(),
      newStatus,
      reason: value === "other" ? reason : value,
    });
    setReason('')
    toggle(!visible);
  };

  const handleCancel = () => {
    setReason('')
    toggle(!visible);
  };

  return (
    <Modal
      title={`Deactivate Mill`}
      visible={visible}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: false }}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          role="submit"
          disabled={!(reason || value)}
          onClick={handleSubmit}
        >
          Update
        </Button>,
      ]}
    >
      <div>
        <div>Deactivation Reason</div>
        <div>
          <Radio.Group onChange={onChangeValue} value={value}>
            <Radio value="Payment issue">Payment issue</Radio>
            <br />
            <Radio value="Quality issues">Quality issues</Radio>
            <br />
            <Radio value="other">
              Other
              {value === "other" ? (
          <Input
                  className="inputField"
                  placeholder="Enter other reason"
                  value={reason || ""}
                  onChange={(e) => setReason(e.target.value)}
                />
              ) : null}
            </Radio>
          </Radio.Group>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateMillDetails: (data) => dispatch(updateMillDetails(data)),
});

export default connect(null, mapDispatchToProps)(Heading);
