import React, { useCallback } from "react";
import { Helmet } from "react-helmet";

import { ProfileAvatar } from "components/UI/ProfileAvatar";
import Button from "components/UI/Button/Button";

import "./styles.scss";
import { USER_ROLES } from "constants/Auth";
import { ReactComponent as UsersIcon } from "assets/images/social/ic-users.svg";

const TeamAvatars = ({ assignedUsers = [], openNextModal, managerId, role, isSupplyHead }) => {

  const manageTeamMembers = () => {
    openNextModal("MANAGE_TEAM_MEMBERS", {
      title: "Manage team",
      assignedUsers,
      managerId,
      ctaSubmitText: "CONFIRM",
      role, 
      isSupplyHead 
    });
  };

  const membersRef = useCallback((node) => {
    if(node){
      const contentRect = node.getBoundingClientRect();
      const bodyHeight = document.body.getBoundingClientRect().height;
      if(contentRect.bottom > bodyHeight) {
        node.parentNode.style.top = 'auto';
        node.parentNode.style.bottom = '0px';
      }
    }
  }, [])

  return (
    <div className="team-content" ref={membersRef}>
      <Helmet
        bodyAttributes={{
          class: "faded_modal_bg",
        }}
      />
      <div className="team-content__container">
        {assignedUsers.map(({ name, id, image, role }) => (
          <div key={id} className="team-content__item">
            <div>
              <ProfileAvatar
                name={name}
                initialLength={2}
                staticColor
                id={id}
                url={image}
              />
              <div className='team-content__item_name'>{name}</div>
            </div>
            <div className='team-content__item_role'>{USER_ROLES[role]}</div>
          </div>
        ))}
      </div>

      <div className='team-content__manage-team'>
      <Button
          category="grid-2 black-ghost"
          type="button"
          className="team-content"
          onClick={manageTeamMembers}
        >
          <UsersIcon />
          Manage Team
        </Button>
      </div>
    </div>
  );
};

export default TeamAvatars;
