import moment from "moment";
import { PropTypes } from "prop-types";
import React, { Component } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import CloseImg from "../../../assets/images/navigation/ic-close.svg";
import TestImage from "../../../assets/img/model.jpg";
import actions from "../../../store/actions";
import "./UserDetails.scss";
import { TIMESTAMP_FORMAT } from "./../../../constants/Date";

class UserDetails extends Component {
  static propTypes = {
    getCollectionsForUser: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    deleteCollection: PropTypes.func.isRequired,
    selectedUserCollections: PropTypes.func
  };

  componentDidMount() {
    this.props.getCollectionsForUser(this.props.userDetails);
  }
  render() {
    const { userDetails, selectedUserCollections } = this.props;

    // Add from getCollectionsForUser
    const collectionDetails = Object.keys(selectedUserCollections).map(id => selectedUserCollections[id]);

    return (
      <div className="user-details" style={{ padding: "1em 2em" }}>
        <PerfectScrollbar className="user-details__scroll">
          <div className="user-details__user flex-col">
            <div className="user-details__img">
              <img src={TestImage} alt="" />
            </div>
            <div className="user-details__name f-sz-l f-w-semi-bold">{userDetails.name}</div>
            <div className="user-details__email">{userDetails.email}</div>
            <div className="user-details__joined_on f-sz-sm">
              Joined on
              {moment(userDetails.created_on)
                .format(TIMESTAMP_FORMAT)
                .toString()}
            </div>
          </div>
          <div className="user-details__assigned-col">
            <div className="user-details__subtitle">Assigned Collections</div>
            <div className="user-details__col-list">
              {collectionDetails.map(collection => (
                <div className="user-details__col-list-item flex">
                  <div className="user-details__col-list-left">
                    <div className="user-details__col-list__header">{collection.name}</div>
                    <div className="user-details__col-list__sub f-sz-sm">
                      {collection.collebration &&
                      collection.collebration[userDetails.id] &&
                      collection.collebration[userDetails.id].role === 1
                        ? "Admin"
                        : "Manager"}
                    </div>
                  </div>
                  <div className="user-details__col-list-right">
                    <img
                      src={CloseImg}
                      alt="delete Collection"
                      onClick={() => this.props.deleteCollection(collection)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedUserCollections: state.collections.selectedUserCollections
});

const mapDispatchToProps = dispatch => ({
  getCollectionsForUser: userDetails => dispatch(actions.getCollectionsForUser(userDetails)),
  deleteCollection: collectionDetails => dispatch(actions.deleteCollection(collectionDetails))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetails);
