import React from "react";
import { Row, Col, Input, Divider } from "antd";
import "./styles.scss";
const AddressTemplate = ({ address }) => {
  const {consigneeFirstName="",consigneeLastName=""} = address;
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <div className="element">
            <Input
              className="inputFormikClear"
              value={address?.addressLine1}
              placeholder="Address Line 1"
              disabled
            />
          </div>
          <div className="element">
            <Input
              className="inputFormikClear"
              value={address?.addressLine2}
              placeholder="Address Line 2"
              disabled
            />
          </div>
          <div className="element">
            <Input
              className="inputFormikClear"
              placeholder="Landmark"
              value={address?.landmark}
              disabled
            />
          </div>
          <div className="element">
            <Input
              className="inputFormikClear"
              value={address?.pinCode}
              placeholder="Pin Code"
              disabled
            />
          </div>
        </Col>
        <Col span={12}>
          <div className="element">
            <Input
              className="inputFormikClear"
              value={address?.city}
              placeholder="City"
              disabled
            />
          </div>
          <div className="element">
            <Input
              className="inputFormikClear"
              value={address?.state}
              placeholder="State"
              disabled
            />
          </div>
          <div className="element">
            <Input
              className="inputFormikClear"
              value={(consigneeFirstName || consigneeLastName) &&`${consigneeFirstName} ${consigneeLastName}`}
              placeholder="Consignee Name"
              disabled
            />
          </div>
          <div className="element">
            <Input
              className="inputFormikClear"
              value={address?.consigneePhone}
              placeholder="Consignee Phone Number"
              disabled
            />
          </div>
        </Col>
      </Row>

      <Divider />
    </>
  );
};

export default AddressTemplate;
