import React, { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import { required, phoneNumber, email } from "./../../../helpers/form-validations";
import Input from "../../UI/Form/Input/Input";
import Radio from "../../UI/Form/Radio/Radio";
import ToggleButton from "../../UI/Form/ToggleButton/ToggleButton";

const formName = "AddEditUserForm";

class AddEditUserForm extends Component {
  render() {
    const {
      handleSubmit,
      initialValues: { id }
    } = this.props;

    return (
      <div className="flex-col">
        {/* <pre>
          {JSON.stringify(this.props.initialValues, null, 2)}
        </pre> */}
        <Form name={formName} id={formName} onSubmit={handleSubmit}>
          {id && <>
            <Field
              component={ToggleButton}
              type="text"
              name="is_enabled"
              label="User status"
            />
            <div className="hide">
              <Field
                component={Input}
                type="hidden"
                name="id"
                label="User ID"
              />
            </div>
          </>}
          <div className="fields-two">
            <Field
              component={Input}
              validate={[required]}
              type="text"
              name="first_name"
              label="First name*"
            />
            <Field
              component={Input}
              type="text"
              name="last_name"
              label="Last name"
            />
          </div>
          <Field
            component={Input}
            validate={[required, email]}
            type="text"
            name="email"
            label="Email ID*"
            disabled={!!id}
          />
          <Field
            component={Input}
            validate={[phoneNumber]}
            type="text"
            name="phone"
            label="Mobile number"
          />
          <Field
            component={Radio}
            name="role"
            label="Role"
            required={true}
            data={[
              { value: '2', label: 'Admin' },
              { value: '3', label: 'Manager' }
            ]}
          />
        </Form>
      </div>
    );
  }
}

export default reduxForm({ form: formName })(
  AddEditUserForm
);
