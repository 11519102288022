import React, { useState, useCallback, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { ProfileAvatar } from 'components/UI/ProfileAvatar';
import Button from 'components/UI/Button/Button';
import CustomInput from 'components/UI/Form/Input/CustomInput';
import { IconMagnifier } from 'components/UI/Icons';
import EmptyScreen from 'components/UI/EmptyScreen';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import './styles.scss';

const OrgSelectUserModal = ({
  users = [],
  ctaSubmitText = "Submit",
  onSubmit,
}) => {
  const [searchText, setSearchText] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [selectedUser, setSelectedUser] = useState(null);
  const cleanSearchText = useMemo(() => searchText.trim().toLowerCase(), [searchText]);

  useEffect(() => {
    if (cleanSearchText.length === 0) {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(users.filter(user => user.name.toLowerCase().includes(cleanSearchText)));
    }
  }, [cleanSearchText, users]);

  const searchTextChangeHandler = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const userSelectHandler = useCallback((user) => {
    setSelectedUser(user);
  }, []);

  const submitHandler = useCallback(() => {
    onSubmit(selectedUser);
  }, [onSubmit, selectedUser]);

  return <div className="org-select-user-modal">
    {users.length === 0
      ? <EmptyScreen
        heading="No users found"
      />
      : <>
        <div className="org-select-user-modal__content">
          <div className="org-select-user-modal__search">
            <CustomInput
              value={searchText}
              prefixText={<IconMagnifier />}
              placeholder="Search Locofast users"
              onChange={searchTextChangeHandler}
              autofocus
            />
          </div>
          <PerfectScrollBar className="org-select-user-modal__users-list">
            {
              filteredUsers.length === 0
                ? <EmptyScreen
                  heading="No matching result"
                  description={<>
                    No results found for <b>"{searchText}"</b>
                  </>}
                />
                : filteredUsers.map((user) => <Button
                  className={classnames("org-select-user-modal__users-item", {
                    'selected': selectedUser && (selectedUser.id === user.id)
                  })}
                  category=""
                  onClick={() => userSelectHandler(user)}
                  type="button"
                >
                  <ProfileAvatar
                    name={user.name}
                    url={user.url || user.image}
                    initialLength={2}
                  />
                  <div className="user-name">{user.name}</div>
                </Button>)
            }
          </PerfectScrollBar>
        </div>
        <ModalFooter
          submit={ctaSubmitText}
          canSubmit={selectedUser && selectedUser.id}
          onSubmit={submitHandler}
        />
      </>
    }
  </div>
};

export default OrgSelectUserModal;
