import React from 'react';
import Tabs from 'components/UI/Tabs';
import './style.scss';

const OrgRowDetailsTab = ({
  org,
  children,
}) => {
  return <Tabs
    className="org-row-details-tabs"
    defaultActiveTabIndex={0}
  >
    {children}
  </Tabs>
};

export default OrgRowDetailsTab;
