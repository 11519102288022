import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Field, reduxForm } from "redux-form";
import { Redirect } from "react-router-dom";
import qs from "query-string";
import { Helmet } from "react-helmet";
import Input from "./../../UI/Form/Input/Input";
import PasswordInput from "./../../UI/Form/PasswordInput/PasswordInput";
import Button from "../../UI/Button/Button";
import { required, passwordsMustMatch } from "./../../../helpers/form-validations";
import { navigateIfLoggedIn, redirectToBrandSite } from "./../../../helpers/AuthHelper";
import { getCurrentUserRole } from "../../../helpers/AuthHelper";
import { PAGE_TITLES } from "../../../constants/Titles";
import LfLogo from "./../../../assets/images/image/ic-header-logo.png";
import LfLogoBlue from "./../../../assets/images/locofast-blue.png";
import "./CreatePassword.scss";

const formName = "CreatePassword";

class CreatePassword extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
  }

  state = {
    password: '',
    email: ''
  }

  componentDidMount() {
    const { token } = qs.parse(window.appHistory.location.search);
    const userRole = getCurrentUserRole();

    // if some is already logged in, no need to validate the token
    if (userRole) {
      return;
    }

    this.props.validateCreatePasswordToken({ token });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      authValidateCreatePasswordToken: { email: nextPropsEmail }
    } = nextProps;

    if (nextPropsEmail !== prevState.email) {
      return { email: nextPropsEmail };
    } else {
      return null;
    }
  }

  handleFormSubmit(values) {
    const {
      authValidateCreatePasswordToken: {
        token
      },
      onSubmit: submitCreatePassword
    } = this.props;

    this.setState({ password: values.password })

    submitCreatePassword({ ...values, token });
  }

  loginUser = () => {
    this.props.authLogin({
      email: this.state.email,
      password: this.state.password
    })
  }

  renderErrorContent() {
    return <>
      <header>
        <h1>Oops!</h1>
        <div className="desc f-color-faded">
          It seems either the link is invalid or has expired.
        </div>
      </header>
      <footer>
        Powered by <img src={LfLogo} alt="Locofast" />
      </footer>
    </>;
  }

  renderSuccessContent() {

    // once the user is really logged in, he would be navigated to the correct screen
    navigateIfLoggedIn();

    return <>
      <header>
        <h1>Updated!</h1>
        <div className="desc f-color-faded">
          Your password has been updated!
        </div>
      </header>
      <div className="content">
        <Button type="submit" category="large" onClick={this.loginUser}>
          Go to Dashboard
        </Button>
      </div>
      <footer>
        Powered by <img src={LfLogo} alt="Locofast" />
      </footer>
    </>;
  }

  renderFormContent() {
    const {
      authValidateCreatePasswordToken: {
        name,
      },
      authCreatePassword: {
        inProgress
      },
      handleSubmit,
      hostBrand,
    } = this.props;

    return <>
      <Helmet
        bodyAttributes={{
          class: 'body_container'
        }}
        title={PAGE_TITLES.setNewPassword()}
      />
      <header>
        {
          hostBrand
            ? <img src={`https://s3.ap-south-1.amazonaws.com/brand.logo/${hostBrand.id}.png`} style={hostBrand.style} />
            : <img src={LfLogoBlue} alt="" />
          // <LfSvgLogo className="lf-logo" />
        }
        <div className="heading">
          <div className="h2">Hi {name}!</div>
          <div className="h1">Set a new password</div>
        </div>
      </header>
      <form onSubmit={handleSubmit(values => this.handleFormSubmit(values))} > {/* TODO: upgrade form to use formik */}
        <fieldset disabled={inProgress}>
          <Field
            name="password"
            id="password"
            component={PasswordInput}
            validate={[required]}
            type="password"
            label="Enter password"
            placeholder="Password"
          />
          <Field
            name="confirm-password"
            id="confirmPassword"
            component={Input}
            validate={[required, passwordsMustMatch]}
            type="password"
            label="Confirm password"
            placeholder="Confirm password"
          />
          <Button type="submit" category="large">
            Update Password
          </Button>
        </fieldset>
        {
          hostBrand
            ? <footer>
              Powered by <img src={LfLogo} />
            </footer>
            : null
        }
      </form>
    </>;
  }

  render() {
    const {
      authValidateCreatePasswordToken: {
        inProgress: tokenInProgress,
        error: tokenError,
        success: tokenSuccess,
        brandId: authBrandId,
      },
      authCreatePassword: {
        success,
        error
      },
      userDetails,
      hostBrand,
    } = this.props;

    // if there is already someone logged in, redirect to the main landing page
    // instead of showing the create password form
    if (userDetails && userDetails.userRole) {
      return <Redirect to="/" />;
    }

    if (tokenInProgress) {
      return 'Loading';
    }

    if (authBrandId && (!hostBrand || (hostBrand.id !== authBrandId))) {
      redirectToBrandSite(authBrandId);
    }

    return (
      <div className="create-password">
        {(error || tokenError || !tokenSuccess)
          ? this.renderErrorContent()
          : success
            ? this.renderSuccessContent()
            : this.renderFormContent()}
      </div>
    );
  }
}

export default reduxForm({
  form: formName
})(CreatePassword);
