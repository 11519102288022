export const STEP_1 = "1";
export const STEP_2 = "2";
export const STEP_3 = "3";

const STEPS = {
  [STEP_1]: {
    prev: null,
    next: STEP_2,
    keys: [
      "customerId",
      "category",
      "fabricCategoryId",
      "fabricSubcategoryId",
      "categoryComposition",
      "loomType",
    ],
  },
  [STEP_2]: {
    prev: STEP_1,
    next: STEP_3,
    keys: [
      "productionSubType",
      "qualitySpecification",
      "epi",
      "ppi",
      "weight",
      "width",
      "x",
      "y",
      "isPhysicalSwatchShared",
      "numberOfColors",
      "certifications",
      "image",
      "color",
    ],
  },
  [STEP_3]: {
    prev: STEP_2,
    next: null,
    keys: [
      "quantity",
      "targetPrice",
      "dueDate",
      "creditPeriod",
      "additionalDetails",
      "currencyId",
      "preferredQuoteType",
    ],
  },
};

export const FABRIC_TYPES = [
  {
    label: "Knits",
    value: "1",
    unitValue: "1",
  },
  {
    label: "Woven",
    value: "2",
    unitValue: "2",
  },
];

export const RT_GREIGE = "1";
export const RT_FINISHED_FABRIC = "2";

export const RFD_REQUIREMENT_INDEX = 1;
export const DYED_REQUIREMENT_INDEX = 2;
export const PRINTED_REQUIREMENT_INDEX = 3;

export const REQUIREMENT_TYPES = [
  {
    label: "Greige",
    value: "1",
    typeValue: RT_GREIGE,
  },
  {
    label: "RFD",
    value: "3",
    typeValue: RT_FINISHED_FABRIC,
  },
  {
    label: "Dyed",
    value: "4",
    typeValue: RT_FINISHED_FABRIC,
  },
  {
    label: "Printed",
    value: "5",
    typeValue: RT_FINISHED_FABRIC,
  },
  {
    label: "Yarn Dyed",
    value: "2",
    typeValue: RT_GREIGE,
  },
];

export const PRODUCTION_SUB_TYPES = {
  Greige: "1",
  YarnDyed: "2",
  RFD: "3",
  Dyed: "4",
  Printed: "5",
};

// Quality Specifications Tab
export const QUALITY_TABS = [
  {
    title: "Greige",
    key: "greige",
    value: 1,
    filters: [],
    default: "",
  },
  {
    title: "Finished Fabric",
    key: "finished_fabric",
    value: 2,
    filters: [],
    default: "",
  },
];

export const GRIEGE_QUALITY_SPECIFICATION = 1;
export const FINISHED_FABRIC_QUALITY_SPECIFICATION = 2;
export const YARN_DYED_QUALITY_SPECIFICATION = 3;
export const PRODUCT_TYPES = [
  // {
  //   id: 1,
  //   name: "Knits",
  // },
  {
    id: 2,
    name: "Woven",
  },
];
export const LOOM_TYPES = [
  {
    id: 1,
    name: "Power Loom",
  },
  {
    id: 2,
    name: "Sulzer",
  },
  {
    id: 3,
    name: "Air Jet",
  },
];
export const CATEGORY_TYPES = [
  {
    id: 1,
    name: "Cotton",
  },
  {
    id: 2,
    name: "Rayon",
  },
];
export const WIDTH_SCALE = ["38", "48", "54", "63", "120"];

export const PHYSICAL_SWATCH_OPTIONS = [
  {
    label: "Yes",
    value: "1",
  },
  {
    label: "No",
    value: "0",
  },
];

export default STEPS;
