import { AuthRoleIds } from "./Auth";
export const EXCLUDE_MAPPED = "excludeMapped";
export const ALL_LF_ROLES = "All";

export const USERS_LIST_FILTERS = {
  [EXCLUDE_MAPPED]: 1,
  roles: [
    AuthRoleIds.LOCO_SUPER,
    AuthRoleIds.FABRIC_SUPPLIER,
    AuthRoleIds.BDE,
    AuthRoleIds.PRODUCT_DEVELOPMENT,
    AuthRoleIds.PRODUCTION_MERCHANT,
    AuthRoleIds.ACCOUNT_MANAGER,
    AuthRoleIds.SUPPLIER_MANAGER,
    AuthRoleIds.SALES_HEAD,
    AuthRoleIds.SUPPLY_HEAD
  ],
};
