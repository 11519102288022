import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { Helmet } from "react-helmet";
import { navigateIfLoggedIn } from "../../../helpers/AuthHelper";
import Button from "../../UI/Button/Button";
import { email, required } from "./../../../helpers/form-validations";
import Input from "./../../UI/Form/Input/Input";
import { PAGE_TITLES } from "../../../constants/Titles";
import LfLogo from "./../../../assets/images/image/ic-header-logo.png";
import LfLogoBlue from "./../../../assets/images/locofast-blue.png";
import "./CustomLogin.scss";

class CustomLogin extends Component {
  render() {
    const {
      handleSubmit,
      hostBrand: brandData
      // pristine, reset, submitting
    } = this.props;

    // once the user is really logged in, he would be navigated to the correct screen
    navigateIfLoggedIn();

    return (
      <div className="custom-login">
        <Helmet
          bodyAttributes={{
            class: 'body_container'
          }}
          title={PAGE_TITLES.login()}
        />
        <header>
          {
            brandData
              ? <img src={`https://s3.ap-south-1.amazonaws.com/brand.logo/${brandData.id}.png`} style={brandData.style} />
              : <img src={LfLogoBlue} />
          }
        </header>
        <h1>Login to your account</h1>
        <form onSubmit={handleSubmit}>
          <Field
            name="email"
            id="email"
            component={Input}
            type="text"
            validate={[email, required]}
            label="Email"
            placeholder="Email"
          />
          <Field
            name="password"
            id="password"
            component={Input}
            validate={[required]}
            type="password"
            label="Password"
            placeholder="password"
          />
          <Link className="link" to="/auth/forgotPassword">
            <div className="login__forgot-password f-w-semi-bold">Forgot password?</div>
          </Link>
          <Button type="submit" category="large">
            Login
          </Button>
          {
            brandData
              ? <footer>
                Powered by <img src={LfLogo} />
              </footer>
              : null
          }
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: "login"
})(CustomLogin);
