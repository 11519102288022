import React, { useMemo } from "react";
import classnames from "classnames";
import OrgRow from "../OrgRow";
import "./style.scss";
import List from "../../../HOCs/list";

const OrgTable = ({
  path,
  className,
  data,
  headers,
  gridTemplateColumns,
  expandable,
  rowRenderer = (org) => null,
  rowDetailsRenderer = (org) => null,
  onOrgEdit = (org) => null,
  onOrgExpandChange = (org, isExpanded) => null,
}) => {
  const memoGridTemplateColumns = useMemo(
    () => `${gridTemplateColumns} 24px 24px`,
    [gridTemplateColumns]
  );

  const CustomerListRow = ({ index, style }) => {
    return (
      <OrgRow
        key={data[index].id}
        org={data[index]}
        gridTemplateColumns={memoGridTemplateColumns}
        onOrgEdit={() => onOrgEdit(data[index])}
        onOrgExpandChange={(isExpanded) =>
          onOrgExpandChange(data[index], isExpanded)
        }
        rowRenderer={rowRenderer}
        style={style}
      />
    );
  };
  return (
    <div className={classnames("org-settings-table", className)}>
      <div
        className="org-settings-table__head"
        style={{
          gridTemplateColumns: memoGridTemplateColumns,
        }}
      >
        {headers.map((header) => (
          <div className="org-settings-table__heading">{header}</div>
        ))}
      </div>
      {data && (
        <List
          itemCount={data?.length}
          itemSize={80}
          height={window.innerHeight - 200}
          style
        >
          {CustomerListRow}
        </List>
      )}
    </div>
  );
};

export default OrgTable;
