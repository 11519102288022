import { Field } from "formik";
import React from "react";
import Status from "../status";
import { ReactComponent as Check } from "assets/images/fabricQualities/Check.svg";
import style from "./style.module.scss";
import PropTypes from "prop-types";

const RadioField = ({
  name,
  label,
  type,
  placeholder,
  isRequired = false,
  options,
  onSelect,
  isUpdate,
}) => {
  return (
    <Field name={name}>
      {({ field, meta: { error, touched }, form: { setFieldValue } }) => {
        return (
          <div className={style["input-wrapper"]}>
            {label ? (
              <p className={style.label}>
                <span>{label}</span>
                {isRequired && <Status isFilled={field?.value > 0} />}
              </p>
            ) : null}

            <div className="flex">
              {options.map(({ id, name }) => (
                <div
                  className={style[field.value === id ? "btnClick" : "btn"]}
                  style={
                    isUpdate ? { cursor: "no-drop", opacity: 0.8 } : { cursor: "pointer" }
                  }
                  onClick={() => {
                    if (!isUpdate) setFieldValue(field.name, id);
                    if (field.name === "productCategory" && !isUpdate)
                      setFieldValue("subCategory", []);
                  }}
                >
                  <div className={style["box"]}>
                    {field.value === id && <Check />}
                    <span>{name}</span>
                  </div>
                </div>
              ))}
            </div>
            {touched && error ? <p className={style.error}>{error}</p> : null}
          </div>
        );
      }}
    </Field>
  );
};
RadioField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
};

RadioField.defaultProps = {
  options: [],
};
export default RadioField;
