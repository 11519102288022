import { createAction } from "redux-actions";
import axios from "axios";
import { get_service_endpoint } from "../../ServiceEndpoints";
import { closeModal } from "./GlobalActions";
import { flash } from "./CommonActions";
import { CREATE_CUSTOMER, getOrgUsers, UPDATE_CUSTOMER } from "./UserActions";
import { copyBrandFeatures, getAddressOneLineObj } from "helpers/brandHelpers";

const apiv2 = get_service_endpoint("apiv2");

const GET_ALL_BRANDS_INFO = createAction("GET_ALL_BRANDS_INFO");
const GET_USERS_FOR_BRAND = createAction("GET_USERS_FOR_BRAND");

const GET_BRAND_FEATURES = createAction("GET_BRAND_FEATURES");
const GET_BRAND_ADDRESSES = createAction("GET_BRAND_ADDRESSES");

const CREATE_BRAND = createAction("CREATE_BRAND");
const UPDATE_BRAND = createAction("UPDATE_BRAND");

const processedData = arg => {
  const data = { ...arg };

  if (data['address'] && !Array.isArray(data['address'])) {
    data['address'] = [data['address']];
  }

  data['brandAddress'] = JSON.stringify(data['address']);
  delete data['address']

  if (!data.type) {
    data['type'] = "2";
  }

  if (data['type'] == 2) {
    copyBrandFeatures(data, data);
  }

  data.locofastRootType = data.type;
  delete data.type;

  return data;
}

export const createNewBrand = brandData => dispatch => {
  const { image } = brandData;

  const data = processedData(brandData);
  const formData = new FormData();

  Object.keys(data).forEach((k, index) => {
    if (data[k] instanceof File) {

    } else if (typeof (data[k]) === 'boolean') {
      formData.append(k, +data[k])
    } else {
      formData.append(k, data[k])
    }
  });
  formData.append("brandLogo", image || null);

  return axios
    .post(`${apiv2}/brand`, formData)
    .then(res => {
      dispatch(CREATE_BRAND({
        ...data,
        id: res.data,
        name: brandData.name,
        web_url: brandData.webUrl,
        gst_number: brandData.gstNumber,
        collectionsCount: 0,
        designsCount: 0,
      }));
      dispatch(CREATE_CUSTOMER({
        ...brandData,
        id: res.data,
        type: brandData.type || brandData.locofastRootType,
        address: getAddressOneLineObj(brandData),
      }));
      dispatch(flash({ message: "Brand created successfully" }));
      dispatch(closeModal());
      return res.data;
    })
    .catch(err => {
      dispatch(
        flash({
          message: "Could not create brand"
        })
      );
    });
  // .then(() => window.appHistory.push("/brand/settings/profile"));
};

export const createBrandUser = details => dispatch => {
  details.managers = (Array.isArray(details.managers) && details.managers.filter(m => m.managerId)) || [];
  if (details.role === '21') {
    details.role = '2';
    details.is_superadmin = 1;
  } else if (details.role === '20') {
    details.role = '2';
    details.is_superadmin = 0;
  } else {
    details.is_superadmin = 0;
  }

  const request = {
    ...details,
    is_enabled: '' + (+details.is_enabled),
    company: details.name
  }
  return axios.post(`${apiv2}/brand/users`, request)
    .then(res => {
      dispatch(flash({ message: 'New user added successfully' }));
      dispatch(closeModal());
      dispatch(getOrgUsers({ orgId: details.brandId }));
      return res.data;
    })
    .catch(err => {
      dispatch(
        flash({
          message: "Could not add brand user"
        })
      )
    })
}

export const updateBrandUser = details => dispatch => {
  if (details.role === '21') {
    details.role = '2';
    details.is_superadmin = 1;
  } else if (details.role === '20') {
    details.role = '2';
    details.is_superadmin = 0;
  } else {
    details.is_superadmin = 0;
  }

  const request = {
    ...details
  }
  return axios.patch(`${apiv2}/brand/users`, request)
    .then(res => {
      dispatch(flash({ message: 'User details updated' }));
      dispatch(closeModal());
      dispatch(getUsersForBrand(res.data.brandId))
      return res.data;
    })
    .catch(err => {
      dispatch(
        flash({
          message: "Could not update brand user"
        })
      )
    })
}

export const updateBrandDetails = brandData => dispatch => {
  const data = processedData(brandData);
  const formData = new FormData();

  let imageUpdated;
  Object.keys(data).forEach((k, index) => {
    if (data[k] instanceof File) {
      imageUpdated = data[k];
    } else {
      if (k == "image") {
        formData.append("brandLogo", data[k].imgUrl)
      } else if (typeof (data[k]) === 'boolean') {
        formData.append(k, +data[k])
      } else {
        formData.append(k, data[k])
      }
    }
  });
  if (imageUpdated) {
    formData.append("brandLogo", imageUpdated);
  }

  return axios.patch(`${apiv2}/brand`, formData)
    .then(res => {
      dispatch(UPDATE_BRAND({
        ...data,
        name: brandData.name,
        web_url: brandData.webUrl,
        gst_number: brandData.gstNumber,
        id: brandData.brandId,
      }));
      dispatch(UPDATE_CUSTOMER({
        ...brandData,
        type: brandData.type || brandData.locofastRootType,
        address: getAddressOneLineObj(brandData),
      }));
      dispatch(flash({ message: "Brand details updated" }))
      dispatch(closeModal());
      return res.data;
    })
    .catch(err => {
      dispatch(flash({ message: "Could not update brand details" }))
    })
}

export const getAllLfBrands = () => dispatch => {
  return axios
    .get(`${apiv2}/brand/all`)
    .then(res => {
      dispatch(GET_ALL_BRANDS_INFO(res.data));
      return res;
    }).catch(err => {
      dispatch(
        flash({
          message: "Could not fetch brand details"
        })
      )
    })
}

export const getUsersForBrand = brandId => dispatch => {
  return axios
    .get(`${apiv2}/brand/users`, { params: { brandId, isLoader: true } })
    .then(res => {
      dispatch(GET_USERS_FOR_BRAND({ brandId, list: res.data }));
      return res;
    }).catch(err => {
      dispatch(flash({
        message: err.msg
      }))
    })
}

export const getBrandFeatures = brandId => dispatch => {
  return axios
    .get(`${apiv2}/brands/${brandId}/features`)
    .then(res => {
      dispatch(GET_BRAND_FEATURES({ brandId, ...res.data }));
      return res;
    }).catch(err => {
      dispatch(flash({
        message: err.msg
      }))
    })
}

export const getBrandAddresses = ({ brandId }) => dispatch => {
  return axios
    .get(`${apiv2}/address`, {
      params: {
        brandid: brandId
      }
    })
    .then(res => {
      dispatch(GET_BRAND_ADDRESSES({ brandId, addresses: res.data }));
      return res;
    }).catch(err => {
      dispatch(flash({
        message: err.msg
      }))
    })
}

export const addBrandAddress = ({
  brandId,
  brandName,
  addressLine1,
  addressLine2,
  landmark,
  city,
  state,
  pinCode,
  tag,
}) => dispatch => {
  const body = {
    brandId,
    addressLine1,
    addressLine2: !landmark ? addressLine2 : ((addressLine2 || '').trim() + '\nLandmark: ' + landmark).trim(),
    city,
    state,
    pinCode,
    tag,
  };

  return axios
    .post(`${apiv2}/address`, body)
    .then(res => {
      dispatch(GET_BRAND_ADDRESSES({ brandId, addresses: res.data }));
      dispatch(flash({ message: brandName ? `Address added for ${brandName} successfully` : "Address added successfully" }));
      return res;
    }).catch(err => {
      dispatch(flash({
        message: err.msg
      }))
    })
}
