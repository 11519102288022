import React, { useMemo } from 'react';
import PerfectScrollBar from 'react-perfect-scrollbar';
import classnames from 'classnames';
import OrgRow from '../OrgRow';
import './style.scss';

const OrgTable = ({
  className,
  data,
  headers,
  gridTemplateColumns,
  expandable,
  rowRenderer = (org) => null,
  rowDetailsRenderer = (org) => null,
  onOrgEdit = (org) => null,
  onOrgExpandChange = (org, isExpanded) => null,
}) => {
  const memoGridTemplateColumns = useMemo(() => `${gridTemplateColumns} 24px 24px`, [gridTemplateColumns]);

  return <div className={classnames("partner-settings-table", className)}>
    <div
      className="partner-settings-table__head"
      style={{
        gridTemplateColumns: memoGridTemplateColumns,
      }}
    >
      {headers.map((header) => (<div className="partner-settings-table__heading">
        {header}
      </div>))}
    </div>
    <PerfectScrollBar className="partner-settings-table__body">
      {
        data.map(org => <OrgRow
          key={org.id}
          org={org}
          gridTemplateColumns={memoGridTemplateColumns}
          onOrgEdit={() => onOrgEdit(org)}
          onOrgExpandChange={(isExpanded) => onOrgExpandChange(org, isExpanded)}
          rowRenderer={rowRenderer}
          rowDetailsRenderer={rowDetailsRenderer}
          expandable={expandable}
        />)
      }
    </PerfectScrollBar>
  </div>
};

export default OrgTable;
