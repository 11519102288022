import React, { Component } from "react";
import { PropTypes } from "prop-types";
import PerfectScrollBar from "react-perfect-scrollbar";

import Button from "components/UI/Button/Button";
import { ProfileAvatar } from "components/UI/ProfileAvatar";

import emptyIcon from "assets/img/User/manage-users.svg";
import plusIcon from "assets/images/content/ic-plus.png";

import "./styles.scss";
import { Helmet } from "react-helmet";
import { PAGE_TITLES } from "constants/Titles";

class ManageVendors extends Component {
  componentDidMount() {
    if (Object.keys(this.props.vendors).length === 0) {
      this.props.getMaterialVendors({ brandId: this.props.currentUser.brandId });
    }
  }

  /**
   *
   * @param {User|undefined} user {...} or null
   */
  handleAddEditVendor = (vendor, brandId) => {
    const vendorCopy = {...vendor};
    const isEdit = !!(vendorCopy && vendorCopy.id);
    if(isEdit){
      delete vendorCopy.createdAt;
      delete vendorCopy.updatedAt;
      delete vendorCopy.createdBy;
      delete vendorCopy.updatedBy;
    }
    this.props.openModal(isEdit ? "EDIT_VENDOR" : "ADD_VENDOR", {
      initialValues: {
        ...vendorCopy,
        status: true, //((!user || user.status === undefined) ? true : user.status),
        brandId
      },
      extraFields: { brandId }
    });
  };

  renderHeader(brandId) {
    return (
      <div className="header">
        <div className="top-level-header">
          <div style={{ fontSize: "24px", fontWeight: 600, flex: 1 }}>
            Vendors
          </div>
          <div className="actions">
            <Button
              type="button"
              category="blue-bg"
              onClick={()=>this.handleAddEditVendor(null, brandId)}
            >
              Add Vendor
            </Button>
          </div>
        </div>
        <div className="header-row">
          <div className="col-headers">Company</div>
          <div className="col-headers">Name</div>
          <div className="col-headers">Email ID</div>
          <div className="col-headers">Mobile Number</div>
        </div>
      </div>
    );
  }

  rendervendorsList(vendorsList, brandId) {
    return (
      <PerfectScrollBar>
        <div className="vendors-list-container">
          {vendorsList.map(user => this.renderUserRow(user, brandId))}
        </div>
      </PerfectScrollBar>
    );
  }

  renderUserRow(vendor, brandId) {
    const { id, firstName, lastName, email, mobile, company } = vendor;

    return (
      <div className="vendor-row" key={id}>
        <div className="contact">
          <div className="email">{company}</div>
        </div>
        <div className="profile">
          <div className="flex flex-col flex-v-center">
            <span className="name">
              {firstName} {lastName}
            </span>
          </div>
        </div>

        <div className="contact">
          <div className="email">{email || "--"}</div>
        </div>
        <div className="last-activity">
          <div className="phone">{mobile || "--"}</div>
        </div>
        <div className="flex with-hover">
            <div className="actions">
              <i className="fa fa-pencil" onClick={()=>this.handleAddEditVendor(vendor, brandId)}></i>
            </div>
        </div>
      </div>
    );
  }

  renderEmptyList = brandId => (
    <div className="empty-list-container">
      <div className="img-container">
        <img src={emptyIcon} alt="Empty! Add factory" />
      </div>
      <div className="content">
        <div className="heading">Manage vendors</div>
        <div className="cta">
          <Button category="blue-bg grid-2" onClick={() => this.handleAddEditVendor(null, brandId)}>
            <img src={plusIcon} alt="" />
            <span> Add Vendor</span>
          </Button>
        </div>
      </div>
    </div>
  );

  render() {

    const { brandId }= this.props.currentUser || {};
    return (
      <>
        <Helmet title={PAGE_TITLES.settingsUsers()} />
        {this.props.vendors.length > 0 ? (
          <div className="manage-vendors">
            {this.renderHeader(brandId)}
            {this.rendervendorsList(this.props.vendors, brandId)}
          </div>
        ) : (
          <div className="manage-vendors">{this.renderEmptyList(brandId)}</div>
        )}
      </>
    );
  }
}

export default ManageVendors;
