import React from 'react'
import classnames from 'classnames';

import { ProfileAvatar } from "components/UI/ProfileAvatar";
import Button from "components/UI/Button/Button";

import UndoMemberActionIcon from "assets/images/action/ic-undo.svg";

import { USER_ROLES } from "constants/Auth";

const UserRow = ({
  user,
  userSelectHandler,
  undoHandler,
  primaryActionIcon,
}) => (
  <div
    className={classnames(
      "manage-team-modal__users-item",
      "flex justify-between",
      user.action
    )}
    category=""
    type="button"
  >
    <div className="flex center">
      <ProfileAvatar
        name={user.name}
        url={user.url || user.image}
        initialLength={2}
        staticColor
        id={user.id}
      />
      <div className="user-name">{user.name}</div>
    </div>

    <div className="flex center">
      <div className="user-role">{USER_ROLES[user.role]}</div>
      {user.action ? (
        <Button
          category="btn"
          className="undo"
          data-tip="Undo"
          onClick={undoHandler}
        >
          <img
            src={UndoMemberActionIcon}
            alt=""
            data-tip="Undo"
            data-class="over-modal"
          />
        </Button>
      ) : (
        <Button category="btn" data-tip="Remove" onClick={userSelectHandler}>
          <img
            src={primaryActionIcon}
            alt=""
            data-tip="Remove"
            data-class="over-modal"
          />
        </Button>
      )}
    </div>
  </div>
);

export default UserRow;
