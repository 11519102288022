import React, { useRef } from "react";
import ReactTooltip from 'react-tooltip';

import { ProfileAvatar } from "components/UI/ProfileAvatar";
import "./styles.scss";

const TeamAvatars = ({ assignedUsers = [], viewTeamMembers }) => {
  const extraUsersCount =
    assignedUsers.length > 5 ? assignedUsers.length - 4 : 0;
  const slicedUsers = extraUsersCount
    ? assignedUsers.slice(0, 4)
    : assignedUsers;

  const avatarRef = useRef(null);

  const handleClick = () => {
    let position = { top: '50%' };

    if(avatarRef.current){
      const { top, left } = avatarRef.current.getBoundingClientRect();
      position.top = `${top}px`;
      position.left = `calc(${left}px - 178px)`;
      if(top < 240) {
        position.top = `${240}px`;
      }
    }
    ReactTooltip.hide(avatarRef.current)
    viewTeamMembers(position);
  }

  return (
    <div
      className="assigned-users__avatar"
      onClick={handleClick}
      ref={avatarRef}
      data-tip="View team"
      data-class="v2-tooltip"
      data-place="top"
      data-offset="{'top': 2, 'left': 20}"
    >
      {slicedUsers &&
        slicedUsers.map((user) => (
          <ProfileAvatar
            name={user.name}
            url={user.url || user.image}
            initialLength={2}
            key={user.id}
            id={user.id}
            staticColor
          />
        ))}
      {extraUsersCount > 0 && (
        <ProfileAvatar
          name={`+ ${extraUsersCount}`}
          initialLength={2}
          alias="#E5834C"
        />
      )}
    </div>
  );
};

export default TeamAvatars;
