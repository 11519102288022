import React, {useEffect, useState} from "react";
import "./style.scss";
import { connect } from "react-redux";
import * as Yup from "yup";
import BasicInfo from "./BasicInfo";
import AnnualTurnover from "./AnnualTurnover";
import PerfectScrollBar from "react-perfect-scrollbar";
import VirtualPayment from "./VirtualPayment";
import Address from "./Address";
import CustomerDetailHeader from "./CustomerDetailHeader";
import {
  getAccountManagers,
  getCustomers,
  getCustomerByIdFabric,
  vpaDetails,
  updateAccountManager,
  deactivateCustomer,
  updateBasicInfo,
  updateAnnualTurnover
} from "store/actions/UserActions";
const CustomerDetailsPage = ({
  id,
  getCustomerByIdFabric,
  vpaDetails,
  updateBasicInfo,
  getAccountManagers,
  currentUser,
  deactivateCustomer,
  updateAccountManager,
  customersByID,
  vpaByID,
  updateAnnualTurnover,
}) => {
  const [listOfAM, setListOfAM] = useState([]);

  useEffect(() => {
    getCustomerByIdFabric({ id: +id });
    vpaDetails({id: +id});
  }, [id]);

  useEffect(() => {
    getAccountManagers().then(
      (res) => {
        setListOfAM(res?.payload);
      },
      [getAccountManagers]
    );
  }, [id, getAccountManagers]);
  return (
    <div>
      <CustomerDetailHeader
        customer={customersByID}
        listOfAM={listOfAM}
        currentUser={currentUser}
        deactivateCustomer={deactivateCustomer}
        updateAccountManager={updateAccountManager}
      />
      <PerfectScrollBar className="scrollbar_view">
        <BasicInfo customer={customersByID} updateBasicInfo={updateBasicInfo} />
        <AnnualTurnover
          customer={customersByID}
          updateAnnualTurnover={updateAnnualTurnover}
        />
        <VirtualPayment vpa={vpaByID} />
        <Address customer={customersByID} />
      </PerfectScrollBar>
    </div>
  );
};
const mapStateToProps = (state) => ({
  customersByID: state.user.customerByID.data,
  currentUser: state.auth.userDetails,
  vpaByID: state.user.vpaByID.data.vpaDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomers: () => dispatch(getCustomers({ withCount: 1 })),
  getCustomerByIdFabric: (id) => dispatch(getCustomerByIdFabric(id)),
  vpaDetails: (id) => dispatch(vpaDetails(id)),
  getAccountManagers: () => dispatch(getAccountManagers()),
  updateAccountManager: (data) => dispatch(updateAccountManager(data)),
  deactivateCustomer: (data) => dispatch(deactivateCustomer(data)),
  updateBasicInfo: (data) => dispatch(updateBasicInfo(data)),
  updateAnnualTurnover: (data) => dispatch(updateAnnualTurnover(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetailsPage);
