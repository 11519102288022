import { createAction } from "redux-actions";

const CLOSE_MODAL = createAction("CLOSE_MODAL");
const OPEN_MODAL = createAction("OPEN_MODAL");

const IS_LOADING = createAction("IS_LOADING");
const FINISH_LOADING = createAction("FINISH_LOADING");

const IS_UPLOADING = createAction("IS_UPLOADING");
const FINISH_UPLOADING = createAction("FINISH_UPLOADING");


export const closeModal = () => dispatch => dispatch(CLOSE_MODAL());
export const openModal = (payload, additionalProps = {}) => dispatch =>
  dispatch(OPEN_MODAL({ name: payload, additionalProps: additionalProps }));

export const isLoading = () => dispatch => dispatch(IS_LOADING());
export const isUploading = (fileName) => dispatch => dispatch(IS_UPLOADING(fileName))

export const finishedLoading = () => dispatch => dispatch(FINISH_LOADING());
export const finishedUploading = (pathName) => dispatch => dispatch(FINISH_UPLOADING(pathName));


