import React, { Component } from "react";
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from "redux-form";
import { alphaNumeric, required, phoneNumber } from "../../../helpers/form-validations";
import Input from "../../UI/Form/Input/Input";
import "./FirstLogin.scss";

const formName = "FirstLogin";

class FirstLogin extends Component {
  render() {
    return (
      <div className="first-login" style={{ padding: "1em 2em", width: "400px" }}>
        <div className="first-login__header flex-col">
          <span role="img" aria-label="hand wave emoji">
            👋
          </span>
          <div className="first-login__title f-sz-xxl f-w-bold">Welcome to Locofast</div>
          <div className="first-login__subtitle">Please confirm your name and mobile number to continue</div>
        </div>
        <Form className="first-login__content">
          <div className="hide">
            <Field
              component={Input}
              type="text"
              name="id"
            />
            <Field
              component={Input}
              type="text"
              name="email"
            />
          </div>
          <div className="fields-two">
            <Field
              component={Input}
              type="text"
              validate={[required, alphaNumeric]}
              required={true}
              name="first_name"
              label="First name"
            />
            <Field
              component={Input}
              type="text"
              validate={[alphaNumeric]}
              name="last_name"
              label="Last name"
            />
          </div>
          <Field
            component={Input}
            type="text"
            name="phone"
            label="Phone Number"
            maxLength="10"
            prefixText="+91-"
            validate={[required, phoneNumber]}
            required={true}
          />
        </Form>
      </div>
    );
  }
}

FirstLogin = reduxForm({ form: formName })(FirstLogin);

const mapStateToProps = (state) => ({
  initialValues: state.auth.userDetails
});

export default connect(mapStateToProps)(FirstLogin)
