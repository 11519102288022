import { combineReducers } from "redux";
import { reducer as ReduxFormReducer } from "redux-form";
import AuthReducer from "./AuthReducer";
import GlobalReducer from "./GlobalReducer";
import BrandReducer from "./BrandReducer";
import UserReducer from "./UserReducer";
import LogisticReducer from './LogisticReducer';
import fabricQualitiesReducer from './FabricQualitiesReducer';

export default combineReducers({
  global: GlobalReducer,
  auth: AuthReducer,
  form: ReduxFormReducer,
  brands: BrandReducer,
  logisticsPartner: LogisticReducer,
  user: UserReducer,
  fabricQualities: fabricQualitiesReducer
});
