import moment from "moment";
import { PropTypes } from "prop-types";
import React, { Component } from "react";
import ImgEditPencil from "../../../assets/images/action/ic-edit.svg";
import * as UserRoles from "../../../constants/Auth";
import "./ProfileSettings.scss";
import PrivateContainer from "../../UI/PrivateContainer/PrivateContainer";
import { Helmet } from "react-helmet";
import { PAGE_TITLES } from "../../../constants/Titles";

export default class ProfileSettings extends Component {
  static propTypes = {
    userDetails: PropTypes.object.isRequired,
    updateUserDetails: PropTypes.func.isRequired,
    updateUserImage: PropTypes.func.isRequired,
    deleteUserImage: PropTypes.func.isRequired
  };

  getNameInitials(name) {
    const cleanedName = (name || '').trim();

    if (cleanedName.length === 0) {
      return '';
    }

    return name.trim().split(/(\s+)/g).map(word => word[0].toUpperCase()).join("");
  }

  handleEditPhone = (action) => {
    const { phone } = this.props.userDetails;
    if (action === 'add') {
      this.props.openModal("SET_USER_PHONE");
    } else {
      this.props.openModal("UPDATE_USER_PHONE", {
        initialValues: { phone }
      });
    }
  }

  handleChangePassword() {
    this.props.openModal("CHANGE_PASSWORD", {});
  }

  getUserRoleAsString(userRole) {
    switch (userRole) {
      case UserRoles.LOCO_SUPER:
        return "Loco Admin";
      case UserRoles.BDE:
        return "Loco BDE";
      case UserRoles.PRODUCT_DEVELOPMENT:
        return "Loco Product Development";
      case UserRoles.PRODUCTION_MERCHANT:
        return "Loco Production Merchant";

      case UserRoles.FACTORY_ADMIN:
        return "Factory Admin";
      case UserRoles.FACTORY_MANAGER:
        return "Factory Manager";

      case UserRoles.CLIENT_SUPER:
        return "Brand Admin";
      case UserRoles.CLIENT:
        return "Brand Manager";

      default:
        return "User";
    }
  }

  render() {
    const { name,
      role,
      phone,
      email,
      image,
      created_on,
      password_updated_on,
      isBrandSuperAdmin
    } = this.props.userDetails;
    const isImageValid = image ? true : false;
    const nameInitials = this.getNameInitials(name);
    const strUserRole = isBrandSuperAdmin === 1 ? 'Super Admin' : this.getUserRoleAsString(role);

    return (
      <>
        <Helmet
          title={PAGE_TITLES.settingsMyProfile()}
        />
        <div className="profile-settings">
          <h1>Profile settings</h1>
          <div className="content">
            <div className="profile-fields-container">
              <div className="tabular-fields">
                <div className="field-row">
                  <div className="field-label">Name</div>
                  <div className="field-value">{name}</div>
                </div>
                <PrivateContainer
                  denyRoles={[UserRoles.FACTORY_ADMIN, UserRoles.FACTORY_MANAGER]}
                >
                  <div className="field-row">
                    <div className="field-label">Role</div>
                    <div className="field-value">{strUserRole}</div>
                  </div>
                </PrivateContainer>
                <div className="field-row">
                  <div className="field-label">Email</div>
                  <div className="field-value">{email}</div>
                </div>
                <div className="field-row">
                  <div className="field-label">Mobile</div>
                  <div className="field-value">
                    <div>
                      {
                        phone
                          ? <>
                            {phone}
                            <img
                              className="fly-right link"
                              src={ImgEditPencil}
                              onClick={() => this.handleEditPhone('edit')}
                              alt=""
                            />
                          </>
                          : (<span className="link" onClick={() => this.handleEditPhone('add')}>Add mobile number</span>)
                      }
                    </div>
                  </div>
                </div>
                <div className="field-row">
                  <div className="field-label">Password</div>
                  <div className="field-value">
                    <div><span className="link" onClick={this.handleChangePassword.bind(this)}>Change password</span></div>
                    <div className="f-color-faded">{
                      password_updated_on
                      && <>Last changed {moment(password_updated_on).format('LL')}</>
                    } </div>
                  </div>
                </div>
                <div className="field-row">
                  <div className="field-label">Joined on</div>
                  <div className="field-value">
                    <div>{moment(created_on).format('LLL')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-image-container">
              <div
                className={`img ${isImageValid ? "" : "img-invalid"}`}
                style={{ backgroundImage: `url(${image})` }}>
                {
                  isImageValid
                    ? null
                    : <div className="name-initials">{nameInitials}</div>
                }
              </div>
              <div className="actions">
                <div className="action action-upload-image">
                  <span className="link" onClick={() => this.refs["uploadImage"].click()}>{isImageValid ? "Change" : "Upload photo"}</span>
                </div>
                {
                  isImageValid
                    ? <div className="action action-remove">
                      <span className="link" onClick={this.props.deleteUserImage}>Remove</span>
                    </div>
                    : null
                }
                <input
                  ref="uploadImage"
                  type="file"
                  name="myfile"
                  accept=".jpg,.jpeg,.png"
                  onChange={val => {
                    this.props.updateUserImage({
                      profileimage: val.target.files[0]
                    });
                  }}
                  style={{ display: 'none' }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
