export const isDiff = (initialVal, newVal) =>
  JSON.stringify(initialVal) !== JSON.stringify(newVal);

export const createFormData = (json) => {
  if (!json) {
    return null;
  }

  const formData = new FormData();

  Object.keys(json).forEach(key => {
    formData.append(key, convertToFormValue(json[key]));
  });

  return formData;
}

const convertToFormValue = (value) => {
  if (typeof (value) === 'object') {
    return JSON.stringify(value);
  }

  return value;
}
export const blurOnScroll = (event)=> event.currentTarget.blur();