
const BRANDS_DATA = {
  development: {
    "hosts": {
      "localhost:3000": 138
    },
    "brands": {
      "138": {
        "id": "138",
        "url": {
          "scheme": "https://",
          "host": "localhost:3000",
          "port": ""
        },
        "style": {
          "maxHeight": "95px",
          "maxWidth": "130px"
        },
        showHelp: true,
        isCampusSutra: true,
      }
    }
  },
  testing: {
    "hosts": {
      "test.locofast.in": 179
    },
    "brands": {
      "179": {
        "id": "179",
        "url": {
          "scheme": "https://",
          "host": "test.locofast.in",
          "port": ""
        },
        "style": {
          "maxHeight": "95px",
          "maxWidth": "130px"
        },
        showHelp: true,
        isCampusSutra: true,
      }
    }
  },
  staging: {
    "hosts": {
      "demobrand.locofast.in": 178
    },
    "brands": {
      "178": {
        "id": "178",
        "url": {
          "scheme": "https://",
          "host": "demobrand.locofast.in",
          "port": ""
        },
        "style": {
          "maxHeight": "95px",
          "maxWidth": "130px"
        },
        showHelp: true,
        isCampusSutra: true,
      }
    }
  },
  production: {
    "hosts": {
      "firstcry.locofast.in": 10,
      "campussutra.locofast.in": 60
    },
    "brands": {
      "10": {
        "id": "10",
        "url": {
          "scheme": "https://",
          "host": "firstcry.locofast.in",
          "port": ""
        },
        "style": {
          "maxHeight": "30px",
          "maxWidth": "100px"
        }
      },
      "60": {
        "id": "60",
        "url": {
          "scheme": "https://",
          "host": "campussutra.locofast.in",
          "port": ""
        },
        "style": {
          "maxHeight": "95px",
          "maxWidth": "130px"
        },
        showHelp: true,
        isCampusSutra: true,
      }
    }
  }
};

export default BRANDS_DATA[process.env.REACT_APP_BRAND_ENV] || BRANDS_DATA.production;
