import React, { useState, useEffect, useCallback } from "react";
import { Drawer, Col, Row } from "antd";
import { Form, Formik } from "formik";
import FieldInput from "./FieldInput";
import Select from "./Select";
import * as Yup from "yup";

import { CATEGORY_TYPES, PRODUCT_TYPES } from "./config";
import style from "./style.module.scss";
import { connect } from "react-redux";
import {
  createNewTopQuality,
  getMaterialData,
  getSupplier,
  updateTopQuality,
} from "store/actions/FabricQualitiesActions";
import RadioField from "./RadioField";
import Status from "./status";
import { debounce } from "lodash";
import StatusToggler from 'components/UI/Toggler/StatusToggler';

const TopQualitiesForm = ({
  isUpdate,
  initialFormData,
  visible = false,
  onClose = () => {},
  getMaterialData,
  getSupplier,
  createNewTopQuality,
  updateTopQuality,
  materialData,
  reload,
}) => {
  const [formData, setFormData] = useState({ isInventory: false });
  const validationSchema = Yup.object().shape(
    {
      productCategory: Yup.number().required("Please select any one option"),

      fabricType: Yup.number().required("Please select any one option"),

      loomType: Yup.number().required("Please select any one option"),

      width: Yup.number()
        .positive()
        .max(150, "Maximum value 150")
        .required("Required"),
      epi: Yup.number()
        // .nullable()
        .positive()
        .min(20, "Minimum value 20")
        .max(200, "Maximum value 200")
        .required("Required"),
      // .when("ppi", {
      //   is: (value) => value,
      //   then: Yup.number().required("Required"),
      // }),

      ppi: Yup.number()
        // .nullable()
        .positive()
        .min(20, "Minimum value 20")
        .max(200, "Maximum value 200")
        .required("Required"),
      // .when("epi", {
      //   is: (value) => value,
      //   then: Yup.number().required("Required"),
      // }),

      x: Yup.string()
        .matches(/^(\d+)(\s?\/\s?\d+)?$/, "Format: 30 or 2 / 30")
        // .nullable()
        .required("Required"),
      // .when("y", {
      //   is: (value) => !!value,
      //   then: Yup.string().required("Required"),
      // }),

      y: Yup.string()
        .matches(/^(\d+)(\s?\/\s?\d+)?$/, "Format: 30 or 3 / 30")
        // .nullable()
        .required("Required"),
      // .when("x", {
      //   is: (value) => !!value,
      //   then: Yup.string().required("Required"),
      // }),

      weight: Yup.number()
        .min(0.1)
        .max(3000, "Maximum value 3000")
        .required("Required"),

      certifications: Yup.array(),
      requirementType: Yup.string().required("Please select any one option"),
      supplier: Yup.string()
        .when("isInventory", {
          is: (value) => value,
          then: Yup.string().required("Required"),
          otherwise: Yup.string().nullable(),
        }),
      price: Yup.number()
        .positive()
        .max(3000, "Maximum value 3,000")
        // .nullable(),
        .when("supplier", {
          is: (value) => value,
          then: Yup.number().required("Required"),
          otherwise: Yup.number().optional().nullable(),
        }),
      subCategory: Yup.string().required("Please select any one option"),
      tat: Yup.number()
        .min(1, "TAT should be greater than or equal to 1")
        .max(90, "TAT should be less than or equal to 90 days")
        .when("supplier", {
          is: (value) => value,
          then: Yup.number().required("Required"),
          otherwise: Yup.number().optional().nullable(),
        }),
      minimumOrderQuantity: Yup.number()
        .min(1, "Quantity cannot  be less than one")
        .max(1000000, "Quantity cannot be more than 10,00,000 meters")
        .when("isInventory", {
          is: (value) => value,
          then: Yup.number().required("Required"),
          otherwise: Yup.number().nullable(),
        }),
      maximumOrderQuantity: Yup.number()
        .min(1, "Quantity cannot  be less than one")
        .max(1000000, "Quantity cannot be more than 10,00,000 meters")
        .when("isInventory", {
          is: (value) => value,
          then: Yup.number().required("Required"),
          otherwise: Yup.number().nullable(),
        }),
    },
    [
      ["x", "y"],
      ["epi", "ppi"],
    ]
  );

  useEffect(() => {
    const isInventoryUnit = initialFormData.isInventory;
    setFormData({ ...initialFormData, isInventory: !!isInventoryUnit });
    if (initialFormData?.supplierId) {
      setSupplierId(initialFormData.supplierId);
    }
    onSearch("");
  }, [initialFormData]);

  const [supplierNames, setSupplierNames] = useState([]);
  const [supplierId, setSupplierId] = useState();

  const onSelect = (value, values) => {
    if (values) {
      setSupplierId(values.props.label.supplierId);
    }
  };
  const onSearch = (value) => {
    setSupplierNames([]);
    const paramSearch = {
      searchText: value,
    };

    getSupplier(paramSearch).then((res) => {
      setSupplierNames([]);
      setSupplierNames(res);
    });
  };

  const debouncedSupplierSearch = useCallback(
    debounce((nextValue) => onSearch(nextValue), 350),
    []
  );
  const onSubmitForm = (values) => {
    const form = {
      productTypeId: values.fabricType,
      productCategoryId: values.productCategory,
      requirementType: Number(values.requirementType),
      loomTypeId: values.loomType,
      threadCount: {
        x: values.x,
        y: values.y,
      },
      construction: {
        epi: values.epi,
        ppi: values.ppi,
      },
      width: Number(values?.width),
      weight: Number(values?.weight),
      productSubCategoryId: [Number(values.subCategory)],
      isInventory: values.isInventory ? 1 : 0,
      maximumOrderQuantity: values.maximumOrderQuantity || undefined,
      minimumOrderQuantity: values.minimumOrderQuantity || undefined,
      inventoryUnitPrice: values.inventoryUnitPrice || undefined,
    };
    if (values.fabricType === initialFormData.fabricType)
      delete form.productTypeId;
    if (values.productCategory === initialFormData.productCategory)
      delete form.productCategoryId;
    if (values.requirementType === initialFormData.requirementType)
      delete form.requirementType;
    if (values.loomType === initialFormData.loomType) delete form.loomTypeId;
    if (values.x === initialFormData.x && values.y === initialFormData.y)
      delete form.threadCount;
    if (
      values.epi === initialFormData.epi &&
      values.ppi === initialFormData.ppi
    )
      delete form.construction;
    if (values.width === initialFormData.width) delete form.width;
    if (values.weight === initialFormData.weight) delete form.weight;
    if (values.subCategory === initialFormData.subCategory)
      delete form.productSubCategoryId;
    if (values.certifications === initialFormData.certifications)
      delete form.certificateId;

    if (
      values.certifications !== initialFormData.certifications &&
      values.certifications.length
    ) {
      form.certificateId = values.certifications.map((cur) => {
        return Number(cur);
      });
    }

    if (
      (values.supplier && initialFormData.supplier !== values.supplier) ||
      values.tat !== initialFormData.tat
    ) {
      form.supplierId = supplierId;
      form.tat = Number(values?.tat);
      form.price = Number(values?.price);
    } else if (values.price && initialFormData.price !== values.price) {
      form.price = Number(values?.price);
    }

    if (isUpdate) {
      form.id = initialFormData.id;
      updateTopQuality(form).then((res) => {
        if (res) {
          reload();
          onClose();
        }
      });
    } else {
      createNewTopQuality(form).then((res) => {
        if (res) {
          reload();
          onClose();
        }
      });
    }
  };
  return (
    <Drawer
      destroyOnClose
      width={700}
      className={style["pdc-drawer"]}
      title={isUpdate ? "Edit Top qualities" : "Add Top qualities"}
      size="large"
      onClose={() => {
        onClose();
      }}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Formik
        enableReinitialize
        initialValues={{ ...formData }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSubmitForm(values);
        }}
      >
        {({ handleSubmit, touched, errors, dirty, values, setFieldValue }) => {
          const __validateQualitySpecification = () => {
            const x =
              values.epi && values.ppi
                ? !errors.epi && !errors.ppi
                  ? 1
                  : -1
                : 0;
            const y =
              values.x && values.y ? (!errors.x && !errors.y ? 1 : -1) : 0;
            const z = values.weight ? (!errors.weight ? 1 : -1) : 0;
            return x + y + z >= 2;
          };
          const disableCondition =
            !dirty ||
            // !__validateQualitySpecification() ||
            (dirty && Object.keys(errors).length > 0);
          return (
            <Form className={style.form}>
              <Row className={style["form-row"]} gutter={16}>
                <Col className={style["form-column"]} span={12}>
                  <RadioField
                    isRequired={true}
                    name="fabricType"
                    label="Fabric type"
                    options={PRODUCT_TYPES}
                    isUpdate={isUpdate}
                  />
                </Col>

                <div style={{ paddingTop: "3px", width: "50%" }}>
                  <p className="flex justify-between">
                    <span className={style.name}>Thread Count</span>
                    <Status
                      isFilled={
                        !errors?.x &&
                        !!values?.x?.length &&
                        !errors?.y &&
                        !!values?.y?.length
                      }
                    />
                  </p>
                  <div className='flex'>
                    <Col className={style["form-column"]} span={5}>
                      <FieldInput name="x" placeholder="e.g. 120" type="text" />
                    </Col>
                    <div className={style["cross"]}>X</div>
                    <Col className={style["form-column"]} span={5}>
                      <FieldInput name="y" placeholder="e.g. 120" type="text" />
                    </Col>
                  </div>
                </div>
              </Row>
              <Row className={style["form-row"]} gutter={16}>
                <Col className={style["form-column"]} span={12}>
                  <RadioField
                    isRequired={true}
                    name="productCategory"
                    label="Category"
                    options={CATEGORY_TYPES}
                    isUpdate={isUpdate}
                  />
                </Col>

                <div style={{ paddingTop: "3px", width: "50%"}}>
                  <p className="flex justify-between">
                    <span className={style.name}>Construction</span>
                    <Status
                      isFilled={
                        !errors?.epi &&
                        !!values?.epi &&
                        !errors?.ppi &&
                        !!values?.ppi
                      }
                    />
                  </p>
                  <div className='flex'>
                    <Col className={style["form-column"]} span={5}>
                      <FieldInput name="epi" placeholder="EPI" type="number" />
                    </Col>
                    <div className={style["cross"]}>X</div>
                    <Col className={style["form-column"]} span={5}>
                      <FieldInput name="ppi" placeholder="PPI" type="number" />
                    </Col>
                  </div>
                </div>
              </Row>

              <Row className={style["form-row"]} gutter={16}>
                <Col className={style["form-column"]} span={12}>
                  <Select
                    isRequired
                    isGlobal
                    fieldKey="subCategoryId"
                    fieldValue="subCategoryName"
                    name="subCategory"
                    label="Sub-Category"
                    placeholder="Select Sub-Category"
                    options={
                      materialData.productSubCategory[
                        String(values?.productCategory)
                      ]
                    }
                    isDisabled={isUpdate}
                  />
                </Col>

                <Col className={style["form-column"]} span={12}>
                  <FieldInput
                    isRequired
                    name="weight"
                    label="GSM"
                    placeholder="Enter Weight"
                    type="number"
                    isUnit="GSM"
                  />
                </Col>
              </Row>
              <Row className={style["form-row"]} gutter={16}>
                <Col className={style["form-column"]} span={12}>
                  <Select
                    isGlobal
                    fieldKey="id"
                    fieldValue="name"
                    isRequired
                    name="requirementType"
                    label="Requirement type"
                    placeholder="Select Requirement type"
                    options={materialData.requirementType}
                    isDisabled={isUpdate}
                  />
                </Col>

                <Col className={style["form-column"]} span={12}>
                  <FieldInput
                    isRequired={true}
                    name="width"
                    label="Width"
                    placeholder="Enter Width"
                    type="number"
                    isUnit="inch"
                  />
                </Col>
              </Row>

              <Row className={style["form-row"]} gutter={16}>
                <Col className={style["form-column"]} span={12}>
                  <RadioField
                    isRequired={true}
                    name="loomType"
                    label="Loom type"
                    options={materialData.loomType}
                    isUpdate={false}
                  />
                </Col>

                <Col className={style["form-column"]} span={12}>
                  <Select
                    isGlobal
                    fieldKey="id"
                    fieldValue="name"
                    name="certifications"
                    mode="multiple"
                    label="Certifications"
                    placeholder="Select Certifications"
                    options={materialData.certification}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                  <StatusToggler
                    label={'Is inventory?'}
                    style={style}
                    onFalseLabel={'No'}
                    onTrueLabel={'Yes'}
                    condition={values.isInventory}
                    onChange={e => {
                      if(!values.isInventory){
                        setFieldValue('tat', 2)
                      }
                      setFieldValue('isInventory', !values.isInventory)
                    }}
                  />
              </Row>
              <Row className={style["form-row"]} gutter={10}>
                <Col className={style["form-column"]} span={12}>
                  <Select
                    isSupplier
                    shouldAllowClear={!isUpdate}
                    name="supplier"
                    label="Supplier"
                    placeholder="Select Supplier"
                    onSearch={debouncedSupplierSearch}
                    onSelect={onSelect}
                    options={supplierNames}
                    onClear={() => {
                      console.log("runs");
                      setSupplierId();
                    }}
                  />
                </Col>
                <Col className={style["form-column"]} span={12}>
                  <FieldInput
                    isRequired={!!values.supplier || values.isInventory}
                    name="price"
                    label="Customer X-Factory Price"
                    placeholder="₹"
                    type="number"
                    isUnit="/ meter"
                  />
                </Col>
              </Row>
              <Row className={style["form-row"]} gutter={10}>
                <Col className={style["form-column"]} span={12}>
                  <FieldInput
                    isRequired={!!values.supplier || values.isInventory}
                    name="tat"
                    label="TAT"
                    placeholder="Enter TAT"
                    type="number"
                    isUnit="days"
                  />
                </Col>
                {
                  values.isInventory ? (
                    <Col className={style["form-column"]} span={12}>
                      <FieldInput
                        isRequired={!!values.supplier || values.isInventory}
                        name="inventoryUnitPrice"
                        label="Inventory price"
                        placeholder="₹"
                        type="number"
                        isUnit="/ meter"
                      />
                    </Col>
                  ) : (
                    <Col className={style["form-column"]} span={12}>
                      <div className={style["form-button"]}>
                        {isUpdate && (
                          <button
                            type="button"
                            className={style.cancel}
                            onClick={onClose}
                          >
                            Cancel
                          </button>
                        )}
                        <button
                          type="submit"
                          disabled={disableCondition}
                          className={
                            disableCondition ? style.saveDisabled : style.save
                          }
                          style={isUpdate ? { width: "134px" } : { width: "100%" }}
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  )
                }
              </Row>
              {
                values.isInventory ? (
                  <Row className={style["form-row"]} gutter={10}>
                    <Col className={style["form-column"]} span={12}>
                      <FieldInput
                        isRequired={!!values.supplier || values.isInventory}
                        name="minimumOrderQuantity"
                        label="Minimum quantity"
                        placeholder="Enter minimum quantity"
                        type="number"
                        isUnit="meter"
                      />
                    </Col>
                    <Col className={style["form-column"]} span={12}>
                      <FieldInput
                        isRequired={!!values.supplier || values.isInventory}
                        name="maximumOrderQuantity"
                        label="Maximum quantity"
                        placeholder="Enter minimum quantity"
                        type="number"
                        isUnit="meter"
                      />
                    </Col>
                </Row>
                ) : ''
              }
              {
                values.isInventory ? (
                  <Row className={style["inventory"]}>
                    <Col className={style["form-column"]} span={12}>
                      <div className={style["form-button"]}>
                        {isUpdate && (
                          <button
                            type="button"
                            className={style.cancel}
                            onClick={onClose}
                          >
                            Cancel
                          </button>
                        )}
                        <button
                          type="submit"
                          disabled={disableCondition}
                          className={
                            disableCondition ? style.saveDisabled : style.save
                          }
                          style={isUpdate ? { width: "134px" } : { width: "100%" }}
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                ) : ''
              }
              {console.log(values)}
            </Form>
          );
        }}
      </Formik>
    </Drawer>
  );
};

const mapStateToProps = (state, props) => ({
  materialData: state.fabricQualities.materialData,
});
const mapDispatchToProps = (dispatch) => ({
  getMaterialData: () => dispatch(getMaterialData()),
  getSupplier: (data) => dispatch(getSupplier(data)),
  createNewTopQuality: (data) => dispatch(createNewTopQuality(data)),
  updateTopQuality: (data) => dispatch(updateTopQuality(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopQualitiesForm);
