import { Switch } from 'antd'
import React from 'react'

const StatusToggler = ({label, condition, onChange, onTrueLabel, onFalseLabel, style}) => {
  return (
    <div className={style ? style['status-bar'] : "status-bar"}>
        {
            label ? <div className='mr-2'>{`${label} `}</div> : ''
        }
        <div className='flex'>
            <div className='mr-1'>{onFalseLabel}</div>
            <Switch
                checked={condition}
                onChange={onChange}
            />
            <div className='ml-1'>{onTrueLabel}</div>
        </div>
    </div>
  ) 
}

export default StatusToggler