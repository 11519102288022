import { createAction } from "redux-actions";
import axios from "axios";
import { get_service_endpoint } from "../../ServiceEndpoints";
const searchEndpoint = get_service_endpoint("search");

export const REQUEST_API = createAction("REQUEST_API");

export const showFlashMessage = createAction("SHOW_FLASH_MESSAGE");
export const hideFlashMessage = createAction("HIDE_FLASH_MESSAGE");
export const hideUploadProgress = createAction("HIDE_PROGRESS")

export const flashError = (message, duration = 3000) => {
  return flash({
    type: "error",
    message: message,
    duration: duration,
  });
};

export const flash = (message, duration = 3000) => {
  return dispatch => {
    dispatch(showFlashMessage(message));
    setTimeout(() => dispatch(hideFlashMessage()), duration);
  };
};

export const progress = (message) => {
  return dispatch => {
    dispatch(showFlashMessage(message));
  };
};

export const hideProgress = () => {
  return dispatch => {
    dispatch(hideUploadProgress())
  }
}


export const searchAll = (type, keyword) => dispatch => {
  return axios.get(`${searchEndpoint}/autosuggest`, {
    params: {
      type: type === 'design' ? 'product' : type,
      keyword,
      isLoader: true
    }
  }).then(response => {
    return response.data.resp;
  });
}
