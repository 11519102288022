import { FixedSizeList } from "react-window";
import React from "react";
const List = ({
  children,
  itemCount = 10,
  itemSize = 100,
  height = 400,
  width,
  className = "",
}) => (
  <FixedSizeList
    itemCount={itemCount}
    itemSize={itemSize}
    height={height}
    width={width}
    className={className}
  >
    {({ index, style }) => children({ index, style })}
  </FixedSizeList>
);

export default List;
