import React from "react";
import PropTypes from "prop-types";

import "./Button.scss";

const Button = ({ category, shadow, children, type = "submit", ...props }) => {
  const categories = category
    .split(" ")
    .map(c => `btn--${c}`);

  if (props.disabled) {
    if (!categories.includes('btn--disabled')) {
      categories.push('btn--disabled')
    }
  }

  return (
    <button
      {...props}
      type={type}
      className={`${
        props.className ? props.className + " " : ""
        } btn ${categories.join(' ')}${shadow ? " btn--shadow" : ""}`}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  category: PropTypes.string,
  shadow: PropTypes.bool
};

Button.defaultProps = {
  shadow: false
};

export default Button;
