import moment from 'moment';
import {
  CLIENT,
  CLIENT_SUPER,
  LOCO_SUPER,
  FACTORY_ADMIN,
  FACTORY_MANAGER,
  FABRIC_SUPPLIER,
  BDE,
  PRODUCT_DEVELOPMENT,
  PRODUCTION_MERCHANT,
  ACCOUNT_MANAGER,
  SUPPLIER_MANAGER
} from "./../constants/Auth";
import CustomBrands from '../brands.js'

// FIXME: retire me and use userDetails from state
export const getUser = () => JSON.parse(localStorage.getItem("userDetails"));

export const currentHostName = window.location.hostname;

// FIXME: retire me and use me from state
export const isNotCustomHost = () => window.location.hostname === process.env.REACT_APP_BASE_HOSTNAME

// FIXME: retire me and use userDetails.role from state
export const getCurrentUserRole = () => localStorage.getItem("role");

export const checkBrand = (role) => {
  role = role || getCurrentUserRole();
  return role === CLIENT || role === CLIENT_SUPER;
}

export const checkFactory = (role) => {
  role = role || getCurrentUserRole();
  return role === FACTORY_ADMIN || role === FACTORY_MANAGER;
}

export const checkMerchant = (role) => {
  role = role || getCurrentUserRole();
  return role === PRODUCTION_MERCHANT;
}

export const checkFactoryOrMerchant = () => checkMerchant() || checkFactory()

export const checkSuperAdmin = (role) => {
  role = role || getCurrentUserRole();
  return role === LOCO_SUPER;
}

export const prepareUserDetails = (userDetails) => {
  userDetails = userDetails || {};
  return {
    ...userDetails,
    isBrand: checkBrand(userDetails.role),
    isFactory: checkFactory(userDetails.role),
    isSuperAdmin: checkSuperAdmin(userDetails.role),
  }
}

// checks whether user (role) can create order
// note: except factory anyone can create order
export const canCreateOrder = (role) => {
  role = role || getCurrentUserRole();
  const notAllowedRoles = [PRODUCTION_MERCHANT, FACTORY_ADMIN, FACTORY_MANAGER];
  return notAllowedRoles.indexOf(role) < 0;
}

// checks whether user (role) can edit order
// note: except factory anyone can create order
export const checkCanUpdateOrder = (role) => {
  return canCreateOrder(role);
}

export const canCreatePushNotifications = (role) => {
  role = role || getCurrentUserRole();
  // Product merchandise, factory, brand admin and managers cannot create push notifications
  return role !== CLIENT_SUPER
    && role !== CLIENT
    && role !== FACTORY_ADMIN
    && role !== FACTORY_MANAGER
    && role !== PRODUCTION_MERCHANT;
}

export const canCreateQuote = (role) => {
  role = role || getCurrentUserRole();
  const allowedRoles = [FACTORY_ADMIN, FACTORY_MANAGER, LOCO_SUPER, PRODUCT_DEVELOPMENT];
  return allowedRoles.indexOf(role) > -1
}

export const canCreateDesign = (role) => {
  role = role || getCurrentUserRole();
  const notAllowedRoles = [FACTORY_ADMIN, FACTORY_MANAGER, PRODUCTION_MERCHANT];
  return notAllowedRoles.indexOf(role) < 0;
}

export const showBrandFilter = (role) => {
  role = role || getCurrentUserRole();
  const notAllowedRoles = [FACTORY_ADMIN, FACTORY_MANAGER, CLIENT, CLIENT_SUPER];
  return notAllowedRoles.indexOf(role) < 0;
}
export const navigateIfLoggedIn = (role) => {
  role = role || getCurrentUserRole();
  switch (role) {
    case CLIENT_SUPER: return window.appHistory.push("/brand/collections");
    case CLIENT: return window.appHistory.push("/brand/collections");
    case LOCO_SUPER: return window.appHistory.push("/factory/brands");
    case FACTORY_ADMIN: return window.appHistory.push("/factory/brands");
    case FACTORY_MANAGER: return window.appHistory.push("/factory/brands");
    case FABRIC_SUPPLIER: return window.appHistory.push("/supply/swatchLibrary");
    case BDE: return window.appHistory.push("/sales/swatchLibrary");
    case PRODUCT_DEVELOPMENT: return window.appHistory.push("/manager/brands");
    case PRODUCTION_MERCHANT: return window.appHistory.push("/merchant/brands");
    case ACCOUNT_MANAGER: return window.appHistory.push("/factory/performance");
    case SUPPLIER_MANAGER: return window.appHistory.push("/factory/performance");
    default: return null;
  }
}

export const getHostBrand = () => {
  const userDetails = getUser();

  // host brand is picked by logged in user first, if present
  if (userDetails && userDetails.brandId) {
    return CustomBrands.brands[userDetails.brandId];
  }

  const host = window.location.host;
  const hostBrandId = CustomBrands.hosts[host];

  return CustomBrands.brands[hostBrandId];
}

export const redirectToBrandSite = (brandId) => {
  if (!brandId) {
    const user = getUser();
    brandId = user && user.brandId;
  }

  const customBrand = CustomBrands.brands[brandId];
  if (!customBrand) {
    return false;
  }

  if (customBrand.url.host === currentHostName) {
    return false;
  }

  const url = customBrand.url.scheme + customBrand.url.host + (customBrand.url.port ? `:${customBrand.url.port}` : '');

  window.location.replace(`${url}${window.location.pathname}${window.location.search}`);

  return true;
}

// calls the refreshToken action if token get old (6 Hrs as of now)
export const refreshTokenIfOld = (refreshToken) => {
  const userDetails = getUser();

  if (userDetails && userDetails.id) {
    if (!userDetails.iatToken) {
      refreshToken();
    } else {
      // refresh token if its 6 hours old
      const hours = moment().diff(new Date(userDetails.iatToken * 1000), 'hours');
      if (hours >= 6) {
        refreshToken();
      }
    }
  }
}
